import React ,{ useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const Signup = () => {

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [resp, setResp] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMessage,setErrMessage] = useState("")
  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const regExp = RegExp(
    /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
  )




  useEffect(() => {
    document.title = `Sign Up | AfroMelodiez`
  }, []);
  const handleNameChange = (e) => {
    setName(e.target.value)
  };
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  };
 
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
if(email == '' && password == '' && name== ""){
  setErrMessage("Fields can't be empty.")
  console.log("Feilds required");
  return false;

}



if(!regExp.test(email)){
  setErrMessage( "Invalid E-mail address.")

}
else if(name.length<=3){
  setErrMessage("Minimum Name Length 4 Characters. ")

}else if (password.length <= 5  ) {
  setErrMessage("Minimum Password Length 6 Characters.  ")

}

else{
  let formData = new FormData();

  formData.append("name",(name));
  formData.append("email",(email));
  formData.append("password",(password));
  formData.append("device_type"," 'Web',");
  formData.append("device_token","asdajkshd53746jsdfhksjdfhjshdf8we7r9");
  formData.append("fcm_token","xcdgfdfgfgddhdh");
axios.post(`${process.env.REACT_APP_API_URL}/api/auth/register`,formData )
    
    .then((res) => {
      console.log('sshjtttttttttt',res)
      setResp(res);
      navigate('/signin');
      
    })
    
    .catch(function (error) {
      if (error.response) {

      } else if (error.request) {

        console.log(error.request);
      } else {

        console.log('Error', error.message);
      }
    });
}




  };



  

  const handleChange = (newValue) => {
    setValue(newValue);
  };


  return (
    <div>
        <section className='py-8'>
            <div className='container px-5 m-auto flex'>

                <div className='w-full'>
                    <div className='lg:w-2/5 md:w-4/5 w-11/12  w-full m-auto hero-content text-center'>
                    <div className='w-9/12 m-auto'>
                        <Link to='/'><img className='m-auto w-16 mb-5' src='img/home-page-img/logo.svg'/></Link>
                        
                        <hr style={{height:"5px",maxWidth:"600px", margin:"auto",background:"#1ED760",marginBottom:"20px"}}/>
                        <h4 className='lg:mb-5 md:mb-3 mb-2 lg:text-3xl md:text-2xl text-xl'>Sign up for free to start listening.</h4>
                        </div>
                        <div className='rounded-3xl lg:p-8 md:p-5 p-4 mb-12' style={{boxShadow: "rgb(196 196 196) 0px 0px 8px -1px"}}>
                            <form className='text-left signup-form'>
                            <p style={{color:"red"}} >{errMessage} </p>
                            <input type="email" class="lg:text-xl md:text-base text-sm font-light mt-0 mb-5  lg:py-2 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Enter your email"  onKeyUp={handleEmailChange}/>
                  <input type="email" class="lg:text-xl md:text-base text-sm font-light mt-0 mb-5  lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Confirm your email again"/>
                                <input type="password" class="lg:text-xl md:text-base text-sm font-light mt-0 mb-5  lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Create a password" onKeyUp={handlePasswordChange}/>
                  <input type="text" class="lg:text-xl md:text-base text-sm font-light mt-0 mb-5  lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Enter a profile name" onKeyUp={handleNameChange}/>
                  <p className='lg:text-xl md:text-base text-sm  mb-5'>What's your date of birth?</p>
                  <input type="date" class="lg:text-xl md:text-base text-sm font-light mt-0 mb-5  lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder=""   onChange={handleChange} />
                    
                            <p className='lg:text-xl md:text-base text-sm  mb-8'>What's your gender?</p>

                        <div className='radio-head lg:w-11/12 md:w-11/12 w-full  mb-5 flex flex-wrap justify-between'>
                        <label class="inline-flex mb-4 items-center">
                        <input type="radio" name='gender' value="male"   class="w-4 h-4 border-gray-300 border-2 text-black focus:border-gray-300 focus:ring-black
                        "/>
                      <span class="ml-2 lg:text-lg md:text-base text-sm text-gray-500">Male</span>
                    </label>
                    <label class="inline-flex mb-4 items-center">
                        <input type="radio" name='gender' value="female"   class="w-4 h-4 border-gray-300 border-2 text-black focus:border-gray-300 focus:ring-black
                        "/>
                      <span class="ml-2 lg:text-lg md:text-base text-sm text-gray-500">Female</span>
                    </label>
                    <label class="inline-flex mb-4 items-center">
                        <input type="radio" name='gender' value="Non-binary"    class="w-4 h-4 border-gray-300 border-2 text-black focus:border-gray-300 focus:ring-black
                        "/>
                      <span class="ml-2 lg:text-lg md:text-base text-sm text-gray-500">Non-binary</span>
                    </label>
                   
                        </div>

                        <div className='signup-checkbox-head mb-5'>
                        <div class="flex items-start mb-5 ">
                        <input type="checkbox" class="mt-2 w-4 h-4
                        "/>
                      <span class="ml-2 w-11/12 lg:text-lg md:text-base text-sm text-gray-500">I would prefer not to receive marketing messages from AfroMelodiez.</span>
                    </div>
                    <div class="flex items-start mb-5 ">
                        <input type="checkbox" class="mt-2 w-4 h-4
                        "/>
                      <span class="ml-2 w-11/12 lg:text-lg md:text-base text-sm text-gray-500">Share my registration data with AfroMelodiez content providers for marketing purposes.</span>
                    </div>
                    </div>
                    <p className='lg:text-lg md:text-base text-sm mb-5 text-gray-500'>By clicking on sign-up, you agree to AfroMelodiez <Link to='/terms-and-conditions' className='underline' style={{color:"#1ED760"}}>Terms and Conditions of Use.</Link></p>
                    <p className='lg:text-lg md:text-base text-sm mb-5 text-gray-500'>To learn more about how AfroMelodiez collects, uses, shares and protects your personal data, please see <Link className='underline' to='/privacy-policy'style={{color:"#1ED760"}}>AfroMelodiez Privacy Policy.</Link></p>
                    <div className='text-center mt-12'>
                    <button className='py-3 px-16 rounded-full lg:text-xl text-base text-white inline-block' onClick={(e)=>handleSubmit(e)} style={{background:"#1ED760"}} href=''>SIGN UP</button>
                    </div>
                            </form>

                        </div>

                       
                        <div className='login-back-head'>
                            <p className='lg:text-xl md:text-base text-sm font-bold '>Have an account?<Link to='/signin' style={{color:"#1ED760",marginLeft:"15px"}} >Login</Link></p>
                           
                        </div>
               
                    </div>
                </div>
                
            </div>
        </section>

    </div>
  )
}