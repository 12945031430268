
import React, {useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import demo from '../images2/demo.jpg'
import Player from '../components/Musicplayer/player1';
import StartPlayer from '../playerContext/StartPlayer';
import ScaleLoader  from "react-spinners/ClipLoader";
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import '../App'
import Load from '../components/loader/loader';
import PlayerContext from '../playerContext/playerContext';

const convertminutes=(totalSeconds)=>{
 var minutes = Math.floor(totalSeconds / 60);
   var seconds = totalSeconds % 60;
   if(seconds<10)
   seconds=`0${seconds}`
var time=`${minutes}:${seconds}`
 return time;
}

 const SelectedCategory = () => {
     const navigate=useNavigate()
  const playerctx= useContext(PlayerContext)
  const user=playerctx.isloggedIn
  const play=playerctx.playerStatus
  console.log('isplaying',play)

   const {id}=useParams()
   
   const [category, setCategory] = useState([]);  
   const [musicList , setMusicList]=useState([]);
   const [index , setIndex] = useState(0)
   const [playing,setPlaying] =useState(0)
   const [loading,setLoading]=useState(false)
   const [hover, setHover] = useState(-1);
   const [image,setImage]=useState()


   useEffect(() => {
       Details();
       setIndex(index)
       setPlaying(index)
      }, [])
   const Details = async() => {
         
   const token=playerctx.token
         setLoading(true)
      playerctx.load(true)
       const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/category_song_list/${id}`,{ headers: { "Authorization": `Bearer ${token}` } });
       console.log('iddsfsdf',res.data);
       setLoading(false)
       playerctx.load(false)
       setImage(res.data.Category.image)
      setCategory(res.data.data[0],[index])
      setMusicList(res.data.data)
      if(res.data.data.length > 0){
        playerctx.startPlay(res.data.data,playing,index,index)
        }
      
     }
     const ChangeSongHandler = (data,index) =>{
        // playerctx.startPlay(musicList,index,index,index)
        console.log('change song',data)
        navigate('/song/'+data.id)
       }
     
 const handleMouseIn = (i) => {
   setHover(i);
   
 };

 const handleMouseOut = () => {
   setHover(-1);
 };
 
   return (
       // console.log("hjghjgjgjhgjhgjhg",category.artist_id.image),
    <div>
         
           <section>
               <div className='container m-auto mt-8 mb-12'>
                   <div className='profile-head flex w-9/12'>
                       <div className='w-2/4'>
                          {image&& <img style={{width:"300px",height:"300px"}} className='rounded-full m-auto mb-4' src={image !=='' ? `${process.env.REACT_APP_API_URL}/storage/category/${image}`:demo} alt='Load..' />}
{/* 
                           <div className='grid  grid-cols-3 gap-4'>
                               <div className='flex flex-col items-end'>
                                   <h4 className=' text-3xl font-bold'>150</h4>
                                   <p>Monthaly Listeners</p>
                               </div>
                               <div className='flex flex-col items-center'><hr style={{ width: "1px", height: "50px", background: "#000" }} /></div>
                               <div className='flex flex-col items-start'>

                                   <h4 className=' text-3xl font-bold'>{category && category.followers !== undefined? category.followers.length:'0'}</h4>
                                   <p>Followers</p>
                               </div>

                           </div> */}
                       </div>
                       <div className='w-2/4'>
                           <div>
                               <div className='flex gap-10'>
                                   <p className=' text-3xl font-bold mb-5'>{category ? category.name:''}</p>
                                   {musicList.length > 0 && <button className='rounded-3xl  border border-black mb-5 py-1 text-xl px-8'>Follow</button>}
                               </div>
                              
                           </div>
                         
                           {/* <div className='flex gap-10 justify-center'>
                               <div>
                                   <div style={{ width: "45px", margin: "auto" }}>
                                   <a href={category ? category.facebook:''} className="social-link">
                                       <img src='/img/artistprofile/Facebook.svg'></img>
                                       </a>
                                   </div>
                                   <p className='text-center'>Facebook</p>
                               </div>
                               <div>
                                   <div style={{ width: "45px", margin: "auto" }}>
                                   <a href={category ? category.instagram:''}className="social-link">
                                       <img src='/img/artistprofile/Instagram.svg'></img>
                                       </a>
                                   </div>
                                   <p>Instagram</p>
                               </div>
                               <div>
                                   <div style={{ width: "45px", margin: "auto" }}>
                                   <a href={category ? category.snapchat:''} className="social-link">
                                       <img src='/img/artistprofile/snapchat.svg'></img>
                                       </a>
                                   </div>
                                   <p>Snapchat</p>
                               </div>
                               <div>
                                   <div style={{ width: "45px", margin: "auto" }}>
                                       <img src='/img/artistprofile/Twitter.svg'></img>
                                   </div>
                                   <p>Twitter</p>
                               </div>
                               <div>
                                   <div style={{ width: "45px", margin: "auto" }}>
                                   <a href={category ? category.youtube:''}className="social-link">
                                       <img src='/img/artistprofile/youtube.svg'></img>
                                       </a>
                                   </div>
                                   <p>youtube</p>
                               </div>
                           </div> */}

                       </div>
                   </div>
               </div>
           </section>
        <section>
               <div className='container m-auto mb-12 px-5'>
                   <div className='singer-song-list'>
                       <table class="table-auto" style={{ width: "100%" }}>
                           <thead>
                               {musicList.length > 0 && <tr className='text-left border-b border-gray-500 '>
                               
                                   <th className='py-3 pl-5'>1</th>
                                   <th className='py-3 '></th>
                                   <th className='py-3'>Song</th>
                                   <th className='py-3'>Artist</th>
                                   <th className='py-3'>Duration</th>
                               </tr>}
                           </thead>
                           <tbody>
                               {loading && <div className='loader'><Load /></div>}
                               {!loading && musicList.length ===0 && <div className='loader1'>No Data for current Category please go back and search for other Categories</div>}
                                {!loading && musicList.map((data,i)=>{
                               return  <tr className='border-b border-gray-500' onClick={()=>ChangeSongHandler(data,i)}>
                                  <td className='w-10 py-3 pl-5' onMouseOver={()=>handleMouseIn(i)} onMouseOut={()=>handleMouseOut()}>
             {playerctx.playing===i && play && <img src= '/img/music/HeftyEarlyAegeancat-max-1mb.gif'/>}
             {playerctx.playing===i && !play && i+1 }
      {hover===i && playerctx.playing!==i && <PlayArrowIcon />}
      {hover!==i && playerctx.playing!==i && i+1}
     </td>
                                   <td className='py-3  flex  items-center'>
                                   <img style={{width:"40px",marginRight:"5px"}} src={data.song_image!==''?`${process.env.REACT_APP_API_URL}/storage/song/images/${data.song_image}`:demo} /> </td>
                                   <td className='py-3'>{data.name}</td>
                                   <td className='py-3'>{data.artist_id.name}</td>
                                   <td className='py-3'>{convertminutes(Number(data.duration))}</td>
                               </tr>
                              })}
                           </tbody>
                       </table>
                   </div>
               </div>
               </section>
               <div>
              
               </div>
       </div>
   )
}

export default SelectedCategory