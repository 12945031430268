import React, { useState } from "react";

import axios from 'axios';
import { Details } from "@material-ui/icons";
import zIndex from "@material-ui/core/styles/zIndex";


const PlayerContext =React.createContext({
    token:'',
    playlist:[],
    playerStatus:false,
    musicList:[],
    currentPlayList:[],
    start:(id)=>{},
    stop:()=>{},
    play:()=>{},
    pause:()=>{},
    isloggedIn:false,
    liked:false,
    login:(token)=>{},
    logout:()=>{},
    userId:'',
    startPlay:(music,playing,indexset,index)=>{},
    index:-1,
    playing:-1,
    indexset:-1,
    previous:()=>{},
    next:()=>{},
    loading:false,
    load:(loadState)=>{},
    songLiked:(liked)=>{},
    playlistdata:(songData)=>{}
    
  });

  export const PlayerContextProvider=(props)=>{
    const initialToken=localStorage.getItem('token');
    const initialUserId=localStorage.getItem('userID')
    const[token,setToken] = useState(initialToken);
    const [liked,setLiked]=useState(false)
    const[currentPlayList,setCurrentPlayList]=useState([])
    const[userId,setUserId]=useState(initialUserId)
    const[playerStatus,runPlayerStatus] = useState(false);
   const [musicList , setMusicList]=useState({id: 364, name: 'Soo Dadaal',song_image: "1653080312_00.jpeg", song: '1653080312_MursalMuusevsHodanAbdirahman-Soodadaal-(OfficialVideo)2020.mp3'});
   const[playing,setPlaying]=useState(-1)
   const[index,setIndex]=useState(-1)
   const[indexset,setIndexset]=useState(-1)
   const[loading,setLoading]=useState(false)
   const userIsLoggedIn= !!token
   const startPlayer=(id,url)=>{
    setPlaying(-1)
    setIndexset(-1)
    setIndex(-1)
      Details(id,url)
}
const startPlay=(music,playing,indexset,index)=>{
    console.log('busiiii',music,playing,indexset,index)
 setMusicList(music) 

 setPlaying(playing)
 setIndexset(indexset)
 setIndex(index)

}
const Details = async(id,url) => {
    let count=0
    let token=localStorage.getItem('token')

    const res = await axios.get(url,{ headers: { "Authorization": `Bearer ${token}` } });

  
   
   var song= res.data.song?res.data.song:res.data.Message
   song= res.data.data?res.data.data:song
   console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',song,id)
   if (!song){
    alert('no songs available')
    return
    }
  
   song.map((element) => {
       
        if(id===element.id){
            setMusicList(element)
            console.log("gfffghgg",element)
            runPlayerStatus(true)
            count=1
        }
    });  
   
    
  console.log('aaaaaaaa',musicList)
  if(count===0)
  alert('no songs found')
 }
const play=()=>{
    runPlayerStatus(true)
    console.log('aaaaaaaaaaaaaaaaaaaaplay')
}
const pause=()=>{
    runPlayerStatus(false)
    console.log('aaaaaaaaaaaaaaaaaaaapause')

}

const previous=()=>{
    setPlaying(playing-1)
    setIndexset(indexset-1)
    setIndex(index-1)
    
   

}
const next=()=>{
    setPlaying(playing+1)
    setIndexset(indexset+1)
  setIndex(index+1)
   
}

const login=(token)=>{
    setToken(token);
    localStorage.setItem('token', token)
    getId(token)
    
}
const getId= async(token) => {
     
        
        console.log('userid',token)
   
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/me`,'',{headers: { "Authorization": `Bearer ${token}`}});
   
      console.log('dshgjfgjsdgfjhsdgfjhsgdfjhgd',res)
    localStorage.setItem("userID",res.data.data.id)

  
  }

const logout=()=>{
    setToken(null);
    
     localStorage.removeItem('token')
     localStorage.removeItem('userID')
}


   const stopPlayer=()=>{
    runPlayerStatus(false)
}
const load=(loadState)=>{
    setLoading(loadState)

}
const songLiked=(liked)=>{
setLiked(liked)
}
const playlistdata=(songData)=>{
    setCurrentPlayList(songData)
}

    const contextValue={
        token:token,
        playerStatus:playerStatus,
        start:startPlayer,
        stop:stopPlayer,
        musicList:musicList,
        play:play,
        pause:pause,
        isloggedIn:userIsLoggedIn,
        login:login,
        logout:logout,
        userId:userId,
        startPlay:startPlay,
        index:index,
        playing:playing,
        indexset:indexset,
        previous:previous,
        next:next,
        loading:loading,
        load:load,
        liked:liked,
        songLiked:songLiked,
        currentPlayList:currentPlayList,
        playlistdata:playlistdata
        
    }  


return (
    <PlayerContext.Provider value={contextValue}>
        {props.children}
    </PlayerContext.Provider>
    )
}
export default PlayerContext;