import React,{useContext,useState,useEffect} from 'react'
import PlayerContext from '../../playerContext/playerContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Redirectpage from '../../pages/Redirectpage';
const Myplaylist = () => {
  const navigate = useNavigate();
    const tokenctx=useContext(PlayerContext)
    const id=tokenctx.userId    
    const isloggedin=tokenctx.isloggedIn
   const [songId, setSongId] = useState();
    const[playlist,setPlayList]=useState([])
    const Music = async() => {
    const token=tokenctx.token
    
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/user_playlist_get/${id}`,{ headers: { "Authorization": `Bearer ${token}` } });
    console.log('musiccccccccccc',res)
    
     setPlayList(res.data.Data)
    
    }
    useEffect(() => {
      
        document.title = `My Playlist | AfroMelodiez`
 
      Music();
       
     }, [])
     const songlist=(playlist)=>{
       tokenctx.playlistdata(playlist.song_ID)
      navigate("/my-playlist/"+playlist.playlist_name)

     }
  return (
    <div className="w-full px-10">
    {!isloggedin && <Redirectpage />}
   
    {isloggedin &&<div className="container flex m-auto py-12">
  
    { playlist && playlist.map((playlist)=>( <div onClick={()=>songlist(playlist)} className='w-40 grid text-center px-2'>
      <img className='rounded-md' src='/img/playlist.webp'/>
      <span className='font-latto text-base mt-1'>{playlist.playlist_name}</span>
      
    
      </div>))}
      </div>}
    </div>
  )
}

export default Myplaylist