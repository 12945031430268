import React,{useState}from 'react'
import CookieConsent, { Cookies } from "react-cookie-consent"
import { Link } from 'react-router-dom'

const Modalhome = () => {
  


  return (
   
      <CookieConsent
  location="bottom"
  buttonText="X"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#000000e6", padding:"9px 00px",justifyContent: "center",    flexWrap: "no-wrap"}}
  contentStyle ={{margin:'15px',    flex: "unset",fontSize:"18px"}}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" , background:"#aaaaaa",borderRadius:"50%"  }}
  expires={150}
>

            <p  >  This site uses cookies. By using this site, you agree to our  &nbsp;
            <Link target="_new" to="/privacy-Policy" data-link="normal" style={{textDecoration:"underline", color:"#20d464",fontWeight:"700"}}>Privacy Policy </Link>&nbsp; and &nbsp;
            <Link target="_new" to="/terms-and-conditions" data-link="normal" style={{textDecoration:"underline", color:"#20d464",fontWeight:"700"}}>Term and Conditions</Link></p>
            
            </CookieConsent>
  )
}

export default Modalhome