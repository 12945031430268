import React ,{useEffect}from 'react'
import './../App.css'

const AboutUs = () => {
//   useEffect(() => {
//     countToHandler();
//     window.addEventListener('scroll', countToHandler, true);

//     return () => {
//         window.removeEventListener('scroll', countToHandler);
//     }
// }, [])

// function countToHandler() {
//     let items = document.querySelectorAll('.count-to');

//     for (let i = 0; i < items.length; i++) {
//         let item = items[i];
//         if (item.getBoundingClientRect().top > 0 && window.innerHeight - item.offsetHeight > item.getBoundingClientRect().top && !item.classList.contains('finished')) {
//             if (item.querySelector('button')) item.querySelector('button').click();
//             item.classList.add('finished');
//         }
//     }
// }

  useEffect(() => {
    document.title = `About | AfroMelodiez`
  }, []);
  return (
    <div className=' privacy-head about-main container px-5'>
    <h2 className="self-center text-2xl font-semibold whitespace-nowrap text-white">About us</h2>
   <p>Africa Tech House created and owns the music and video streaming and download service AfroMelodiez. AfroMelodiez is a music platform created by African entrepreneurs, artists, song writers and entertainers for African people.</p>
   <p>AfroMelodiez aims to create the most extensive and dependable digital music ecosystem in Africa for musicians and content producers.</p>
   <p>Be it on your phone, computer, tablet, or other devices, locating the perfect song or video is easy using AfroMelodiez.</p>
    <p>On AfroMelodiez, there are millions of songs and videos available for streaming. So you can always find the perfect music or video, whether you're driving, exercising, having fun, or just resting. So choose your music preferences or let AfroMelodiez take you by surprise.</p>
    <p>Users may download material or subscribe to a daily, weekly, or monthly plan to store songs and videos for offline playback. Users can also stream music and videos for free online.</p>
    <p>The AfroMelodiez service is currently available on Android, iOS and Web.</p>
    <p>AfroMelodiez aims to build the largest and most sustainable digital music ecosystem for African artists and content providers.</p>
    <p>All questions or enquiries about our services should be directed to support@afromelodiez.com or info@afromelodiez.com.</p>
    </div>
  )
}

export default AboutUs