import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { PlayerContextProvider } from './playerContext/playerContext';




ReactDOM.render(
  
  <BrowserRouter>
 <PlayerContextProvider>
    <App />
    </PlayerContextProvider>
  </BrowserRouter>,

  document.getElementById('root')
);

