import React ,{useState,useEffect, useContext}from 'react'
import axios from 'axios';
import Load from '../components/loader/loader';
import PlayerContext from '../playerContext/playerContext';










const PrivacyPolicy = () => {
  const tokenctx=useContext(PlayerContext)
const [policy,setPolicy]=useState([])
const[loading,setLoading]=useState(false)

useEffect(() => {
  
  document.title = `Privacy Policy | AfroMelodiez`
  Music();
   
 }, [])
 
  const Music = async(props) => {
    
       
    
      setLoading(true);
      tokenctx.load(true)

      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/privacy_policy`);
     console.log('rtrttrtfvggggggggr',res)
  
     setPolicy(res.data.data)
     setLoading(false);
     tokenctx.load(false)

    
    }
  return (
    <div>
    {loading && <Load />}

    
  {!loading && <div className='privacy-head'><h2>{policy.title}</h2>
    <p>{policy.short_description}</p>
    <div dangerouslySetInnerHTML={{ __html: policy.description} } />
    
    </div>
  
}
</div>)
}

export default PrivacyPolicy