import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import axios from 'axios';
// import { GoogleLogin } from 'react-google-login';


import AppleLogin from 'react-apple-login';
import FacebookLogin from 'react-facebook-login';
import { useNavigate } from 'react-router-dom';
import validator from 'validator'
import { Button } from '@mui/material';
import PlayerContext from '../playerContext/playerContext';

export const Signin = () => {
  const loginCtx = useContext(PlayerContext)

  const [success, setSuccess] = useState(null);
  const [googleRes, setGoogleRes] = useState()
  const [email, setEmail] = useState();
  const [loginForm, setLoginForm] = useState({
    email:"",
    password:""
  });
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState('')
  const [user,setUser]=useState({})

  const navigate = useNavigate();
  const regExp = RegExp(
    /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
  )
  const handlecallbackResponse=async(response)=>{
    console.log('jwt id token',response)
    var userObject= await jwt_decode(response.credential)
    console.log(`user object: ${userObject.name}`)
    var body={
      provider_name:'Google',
      name:userObject.name,
      email:userObject.email,
      provider_id:userObject.sub,
      device_type: "Web",
      device_token: "a977BC6D6-6B9C-4F49-AA6E-19B735188CC5",
      fcm_token: "d6uubi615Uzyp6HYxU3JBJ:APA91bFnivHqZUrO4vQKXrG5fbGSm0rkPLFOvaPkFX9CQBpzDsj_LWy0rYpAZ7thRUdS4bO977ivTYXQgpVMhB3GalY5Ss2EB_-EwbNO6qOLb05mjLNpXto9mgcZZQamqsbzh75KTr9o",
      password:'12345678'
    }
    console.log(userObject)
    
    
    const res= await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/socialLogin`,body)
    console.log("response",res);
   
      if (res.data.message) {
        loginCtx.login(res.data.data.access_token)
        navigate('/');
      }

    
   

    setUser(userObject);
    }


    // useEffect=(()=>{
    //   /* global google */
    //   google.accounts.id.initialize({
    //     client_id:"286335992299-ecfu7ntcnoup2skl4i717dpsi0mnjcj3.apps.googleusercontent.com",
    //     callback: handlecallbackResponse
    //   })
    //   google.accounts.id.renderButton(
    //     document.getElementById('siginDiv'),{
    //       theme:'outline',size:'large'
    //     }
    //   )
    // },[]) 

  useEffect(() => {
    document.title = `Sign in | AfroMelodiez`
     /* global google */
     google.accounts.id.initialize({
      client_id:"929888799272-5k1rvlursu7qp31738gqutcnthbmfn7h.apps.googleusercontent.com",

      // client_id:"929888799272-th4ggbvb9jff5ad5d5rccfb7eautbkit.apps.googleusercontent.com",


      callback: handlecallbackResponse
    })
    google.accounts.id.renderButton(
      document.getElementById('siginDiv'),{
        theme:'outline',size:'large'
      }
    )
  }, []);
  const checkData = localStorage.getItem('token');
  if (checkData != null) {
    window.location.href = '/';
  }

  const handleInputChange = (e) => {
    const value=e.target.value;
    const name=e.target.name;
    setLoginForm({...loginForm,[name]:value})
    console.log(loginForm);
  };



  const handleSubmit = (e) => {
    e.preventDefault();
     if (loginForm.email == '' && loginForm.password == '') {
      setErrorMessage("Fields can't be empty.")
      setEmail("")
      console.log("field can not be empty");
     
    } else if (loginForm.password.length <= 6 ) {
      setErrorMessage("Invalid Password.")
      setEmailError("")
   
     
     
    } else if (!regExp.test(loginForm.email)) {
      setEmailError("Invalid e-mail address.")
      setErrorMessage("")
     
    
    }else if(loginForm.password.length <= 6 ){
      setErrorMessage("Invalid Passwoed.")
      setEmailError("")
  
    } else {
   
      axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        email: (loginForm.email),
        password: (loginForm.password),
        device_type: "Web",
        device_token: "asdajkshd53746jsdfhksjdfhjshdf8we7r9",
        fcm_token: "xcdgfdfgfgddhdh",

      })
        .then((res) => {
          if (res.data.success) {
            loginCtx.login(res.data.token)
            navigate('/');
          } else {
            setErrorMessage('wrong crendentials')
            alert('wrong credentials')
          }
        }).catch((error) => {

        })
        setLoginForm({
          email:"",
          password:""
        })
        setErrorMessage("")

    }
  }



  //   if (!validateEmail(e)) {
  //     console.log("hsdgashdahs","email is not valid");

  //   } else {



  // };


  const responseGoogle = (response) => {
    console.log("fsfsfsf", response);
    setGoogleRes(response.profileObj)
    console.log("dasasd", googleRes)
  }
  const responseFacebook = async(response) => {
    console.log("vxszcscxsc",response);
    var body={
      provider_name:'Facebook',
      name:response.name,
      email:response.email,
      provider_id:response.id,
      device_type: "Web",
      device_token: "a977BC6D6-6B9C-4F49-AA6E-19B735188CC5",
      fcm_token: "d6uubi615Uzyp6HYxU3JBJ:APA91bFnivHqZUrO4vQKXrG5fbGSm0rkPLFOvaPkFX9CQBpzDsj_LWy0rYpAZ7thRUdS4bO977ivTYXQgpVMhB3GalY5Ss2EB_-EwbNO6qOLb05mjLNpXto9mgcZZQamqsbzh75KTr9o",
      password:'12345678'
    }

    const res= await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/socialLogin`,body)
    console.log("response",res);
   
      if (res.data.message) {
        loginCtx.login(res.data.data.access_token)
        navigate('/');
      }

  }

  return (
    <div>
      <section className='py-8'>
        <div className='container m-auto flex'>

          <div className='w-full'>
            <div className='lg:w-2/4 md:w-4/5 w-11/12 m-auto hero-content text-center'>
              <div className='w-9/12 m-auto'>
                <Link to='/'> <img className='m-auto w-20 mb-5' src='img/home-page-img/logo.svg' /></Link>
                <h1 className='lg:mb-5 md:mb-3 mb-2 lg:text-2xl md:text-xl text-base'>Free Somali music On AfroMelodiez</h1>
                <hr style={{ height: "5px", maxWidth: "600px", margin: "auto", background: "#1ED760", marginBottom: "20px" }} />
                <h4 className='lg:mb-3 md:mb-3 mb-2 lg:text-base  text-sm'>To continue, log in to AfroMelodiez.</h4>

                <div className='w-6/6 m-auto'>
                  <div className='social-links text-left'>

  
                    {/* <Link to className="flex lg:text-xl md:text-base text-sm items-center bg-black text-white  justify-center m-auto mb-5 py-2 lg:px-12 md:px-8 px-5 rounded-full text-white"><img className='mr-5 w-6' src='img/home-page-img/fb.png' /> Continue with Facebook</Link> */}
                    {/* <div className='google-icon flex lg:text-xl md:text-base text-sm items-center bg-black text-white justify-center m-auto mb-5 lg:px-12 md:px-8 px-5 rounded-full text-white '> 
                            <FacebookLogin
                              appId="1078419026288208"
                              autoLoad={false} 
                              fields="name,email,picture"
                              callback={responseFacebook}
                              cssClass="my-facebook-button-class"
                              icon="fa-facebook"
                            /></div> */}
                            {/* <GoogleOAuthProvider clientId="469412287743-oaq860qi83jdft6ocb0jkdkdndjkeelg.apps.googleusercontent.com">...</GoogleOAuthProvider>; */}

                              <div  className='google-icon flex lg:text-xl md:text-base text-sm items-center  text-black justify-center m-auto mb-5 lg:px-12 md:px-8 px-5 rounded-full text-white ' id="siginDiv">

                              </div>
                    {/* <Link to className='flex lg:text-xl md:text-base text-sm items-center bg-black text-white justify-center m-auto mb-5 py-2 lg:px-12 md:px-8 px-5 rounded-full text-white ' href=''><img className='mr-5 w-6' src='img/home-page-img/google.png' />Continue with Google</Link> */}
                    {/* <div className='google-icon flex lg:text-xl md:text-base text-sm items-center bg-black text-white justify-center m-auto mb-5 lg:px-12 md:px-8 px-5 rounded-full text-white '> 
                        <GoogleLogin  disabledStyle={false}style={false}
                            clientId="469412287743-oaq860qi83jdft6ocb0jkdkdndjkeelg.apps.googleusercontent.com"
                            buttonText="Continue with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                         />
                        </div> */}
                    {/* <div className='google-icon flex lg:text-xl md:text-base text-sm items-center bg-black text-white justify-center m-auto mb-5 lg:px-12 md:px-8 px-5 rounded-full text-white '>
                        <AppleLogin clientId="496574926292-p8ih2pd4galemf4sbstag76hmmv2ptu1.apps.googleusercontent.com" redirectURI="https://redirectUrl.com" />
                        </div> */}
                    {/* <Link to className='flex lg:text-xl md:text-base text-sm items-center bg-black text-white  justify-center m-auto mb-5 py-2 lg:px-12 md:px-8 px-5 rounded-full text-white ' href=''><img className='mr-5 h-6' src='img/home-page-img/apple.png' />Continue with Apple</Link>
                            */}
                    <div className='or-head flex items-center '>
                            <hr style={{ height: "1px", width: "100px", marginLeft: "auto", background: "#5B5B5B", marginRight: "10px" }} /><p className='text-base'>OR</p><hr style={{ height: "1px", width: "100px", marginRight: "auto", background: "#5B5B5B", marginLeft: "10px" }} />
                            </div>
                  </div>
                </div>
              </div>

              <div className=' lg:p-5 md:p-4 p-5 mb-8 w-4/5 m-auto'>
                <form className='text-left' >
               
                  <input
                    type="email"
                    className="focus-visible:none focus:outline-none mt-0 pl-4 mb-5 py-4 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" placeholder="Johndoe@maipl.com"
                    onChange={handleInputChange}
                    value={loginForm.email}
                    name="email"
                    // onKeyUp={handleEmailChange}
                  />
                     <p style={{color:"red"}} >{emailError} </p>
                  <input
                    type="password"
                    className="focus:outline-none mt-0 pl-4 mb-5 py-4 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
                    value={loginForm.password}
                    name="password"
                    onChange={handleInputChange}
                    placeholder="Password"
                    // onKeyUp={handlePasswordChange}
                  />
                    <p style={{color:"red"}} >{errorMessage } </p>
                  <Link to className='lg:text-xl md:text-base text-sm decoration-0 inline-block underline mb-12' style={{ color: "#1B1B1B" }} href=''>Forgot password?</Link>

                  <div className='checkbox-head flex justify-between'>
                    <label className="inline-flex items-center">
                      <input type="checkbox" className="border-gray-300 border-2 text-black focus:border-gray-300 focus:ring-black" />
                      <span className="ml-2 lg:text-xl md:text-base text-sm">Remember me</span>
                    </label>
                    <button className='lg:py-3 py-2 lg:px-12 px-8 p rounded-full text-white inline-block' onClick={(e) => handleSubmit(e)} style={{ background: "#1ED760" }} >LOG IN</button>
                  </div>
                </form>

              </div>


              <div className='w-5/6 m-auto '>
                <div className='social-links text-left'>
                  <p className='lg:text-xl md:text-base text-sm items-center font-bold  text-black text-center mb-5'>Don't have an account?</p>
                  <Link to='/signup' className='block lg:text-xl mb-12 md:text-base text-sm items-center text-gray-900 uppercase font-normal text-center lg:w-4/5 w-11/12 m-auto mb-5 bg-white rounded-full border-2 border-slate-900 border-solid py-3 lg:px-12 md:px-8 px-5' href=''>Sign up for AfroMelodiez.</Link>
                </div>
              </div>


              <div className='signup-head'>

              </div>

            </div>
          </div>

        </div>
      </section >


    </div >
  )
}