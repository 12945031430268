import React,{useEffect} from 'react'
import './../App.css'
const AboutUSForArtists = () => {
  
  
  useEffect(() => {
    document.title = `Afro Melodiez For Artists`
  }, []);

  return (
    <div className='privacy-head about-main container px-5'>
        <h2 className="self-center text-2xl font-semibold whitespace-nowrap text-white">For artists</h2>
<p>You may quickly find out how your fans are responding to your music, thanks to AfroMelodiez for Artists.</p>
<p>Artists and their followers are at the core of all we do. Therefore, we are steadfastly committed to sound quality even with new ways for music enthusiasts to listen to music.</p>
<p>Your music's reception is equally as crucial as its creation. So, just like you meant it to sound, we're committed to providing our subscribers with the most acceptable content experiences.</p>
<p>We want listeners to enjoy music like yours by providing them with uninterrupted access to our entire library through AfroMelodiez.</p>
<p>From inception, we have taken pleasure in our premium sound innovation to ensure your fans enjoy HiFi quality at a lesser cost, so they don't miss a note.</p>
<p>Our hand-crafted playlists, chosen by a team of in-house music lovers, further demonstrate our commitment to providing the best musical experiences.</p>
    </div>

  )
}

export default AboutUSForArtists