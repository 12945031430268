import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import demo from '../images2/demo.jpg'
import { Link } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import '../App'
import Load from '../components/loader/loader';
import PlayerContext from '../playerContext/playerContext';


const convertminutes = (totalSeconds) => {
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = totalSeconds % 60;
    if (seconds < 10) {
        seconds = `0${seconds}`
    }

    var time = `${minutes}:${seconds}`
    return time;
}

const likedsongs = () => {

    const navigate = useNavigate()
    const playerctx = useContext(PlayerContext)
    const play = playerctx.playerStatus
    const myId = playerctx.userId;
    const user = playerctx.isloggedIn
    const { id } = useParams()

    const [hover, setHover] = useState(-1);
    const [loading, setLoading] = useState(false)
    const [musicList, setMusicList] = useState([]);
    const [index, setIndex] = useState(0);
    const [playing, setPlaying] = useState(0)

    const [likedsongs, setLikedSongs]=useState()

    
    useEffect(() => {
        document.title = `Liked Songs | AfroMelodiez`
        likedsong();
        setIndex(index)
        setPlaying(index)



    }, [])
    const likedsong = async () =>{
        const token = playerctx.token
        // console.log("11111111", token);
        setLoading(true)
        playerctx.load(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/user_song_like_get/${myId}`, { headers: { "Authorization": `Bearer ${token}` } });
        
        setLoading(false)

        playerctx.load(false)

        console.log("scfvdss",response)
        setLikedSongs(response.data.Data)
        setMusicList(response.data.Data)
        // console.log("follow", res);
        if (response.data.Data.length > 0) {
            playerctx.startPlay(response.data.Data, playing, index, index)
        }
        
    }
    const handleMouseIn = (i) => {
        setHover(i);

    };
    
    const handleMouseOut = () => {
        setHover(-1);
    };
    
    const ChangeSongHandler = (data, index) => {

        //    playerctx.startPlay(musicList,index,index,index)
        //    console.log('change song',data)
        navigate('/song/' + data.id)

    }
  return (
    <div>
 <section>
                <div className='container m-auto mb-12 px-5'>
                    <div className='singer-song-list'>
                        <table class="table-auto" style={{ width: "100%" }}>
                            <thead>
                                {likedsongs && likedsongs.length > 0 && <tr className='text-left border-b border-gray-500 '>

                                    <th className='py-3 font-bold pl-5'>#</th>
                                    <th className='py-3 font-bold '></th>
                                    <th className='py-3 font-bold'>Artist</th>
                                    <th className='py-3 font-bold'>Title</th>
                                    <th className='py-3 font-bold'>Duration</th>
                                </tr>}
                            </thead>
                            <tbody>
                                {loading && <div className='loader'><Load /></div>}
                                {!loading && likedsongs && likedsongs.map((data, i) => {
                                    return <tr className='cursor-pointer song-list-hover' onClick={() => ChangeSongHandler(data, i)}>
                                        <td className='w-10 py-3 font-bold pl-5' onMouseOver={() => handleMouseIn(i)} onMouseOut={() => handleMouseOut()}>
                                            {playerctx.playing === i && play && <img src='/img/music/HeftyEarlyAegeancat-max-1mb.gif' />}
                                            {playerctx.playing === i && !play && i + 1}
                                            {hover === i && playerctx.playing !== i && <PlayArrowIcon />}
                                            {hover !== i && playerctx.playing !== i && i + 1}
                                        </td>
                                        <td className='py-3 font-bold  flex  items-center'>
                                            <img style={{ width: "40px", marginRight: "5px" }} src={data.song_image !== '' ? `${process.env.REACT_APP_API_URL}/storage/song/images/${data.song_image}` : demo} /> </td>
                                        {/* <td className='py-3 font-bold'>{artist.artist_id.name}</td> */}
                                        <td className='py-3 font-bold'>{data.artist_id.name}</td>
                                        <td className='py-3 font-bold'>{data.name}</td>


                                        <td className='py-3 font-bold'>{convertminutes(Number(data.duration))}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

    </div>
  )
}

export default likedsongs
