import React from 'react'
import { Link } from 'react-router-dom'
import '../App.css'
import { Button } from '@material-ui/core'
const Redirectpage = () => {
  return (
    <div style={{
      backgroundImage: 'url("/img/noLogInBg-44WL2HVM.png")',
      height: "1000px", backgroundRepeat: "no-repeat"
    }}>
 
     <section className='abc'>
  <h2 style={{paddingBottom:'2%'}}>More music creates more fun!</h2>  
  
  
  <Link to="/signin"> <button className='py-3 px-20 rounded-full lg:text-xl text-base text-white inline-block'  style={{ background: "#1ED760" }}>Login</button></Link>
  <Link to="/signup"> <button className='py-3 px-20 rounded-full lg:text-xl text-base text-white inline-block'  style={{ background: "#1ED760" }} >Signup</button></Link>
     
  </section>
  
    </div>
  )
}

export default Redirectpage