import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'

export const ContactUs = () => {

  const navigate = useNavigate();
  const [contactUsForm, setContactUsForm] = useState({
    name: "",
    email: "",
    company_name: "",
    message: ""
  });
  const [err, setErr] = useState({
    nameErr: "",
    emailErr: "",
    company_nameErr: "",
    messageErr: ""
  });
  const [allFieldErr, setallFieldErr] = useState(null);
  const [resp, setResp] = useState();
  const [googleRes, setGoogleRes] = useState()
  const regExp = RegExp(
    /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
  )

  useEffect(() => {
    document.title = `Contact us| AfroMelodiez`
  }, []);

  const handleInput = (event) => {
    const value = event.target.value
    const name = event.target.name

    setContactUsForm({ ...contactUsForm, [name]: value })
    console.log("contactUsForm", contactUsForm);

  }



  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (contactUsForm.name == "" && contactUsForm.company_name == "" && contactUsForm.email == "" && contactUsForm.message == "") {
    setallFieldErr("Please enter all feilds.")
    } else if (!regExp.test(contactUsForm.email)) {
      setallFieldErr("Invalid email address." )
      console.log("Invalid email address.");
    }else if(contactUsForm.message.length<=5){
      setallFieldErr("Min. 6 letters required in message box.")
    }

else {
      const newEntry = { ...contactUsForm }
      const url = `${process.env.REACT_APP_API_URL}/api/contact_us`

      const result = await axios.post(url, newEntry);
      const response = await result
      if (response.data.success == true) {

        Swal.fire({

          icon: 'success',
          title: 'Thank You',
          text: 'We have received your message.',
          confirmButtonColor: "green"

        })

        navigate('/')
      }
      console.log("resp", err)
      setContactUsForm({
        name: "",
        email: "",
        company_name: "",
        message: ""
      })
      setallFieldErr("")
    }




    //   .then((res) => {
    //     console.log('sshjtttttttttt',res)
    //     setResp(res);
    //     // navigate('/');

    //   })

    //   .catch(function (error) {
    //     if (error.response) {

    //     } else if (error.request) {

    //       console.log(error.request);
    //     } else {

    //       console.log('Error', error.message);
    //     }
    //   });
  };



  //   const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  //   const handleChange = (newValue) => {
  //     setValue(newValue);
  //   };
  const responseGoogle = (response) => {
    console.log("fsfsfsf", response);
    setGoogleRes(response.profileObj)
    console.log("dasasd", response)
  }

  return (
    <div>
      <section className='py-8'>
        <div className='container px-5 m-auto flex'>

          <div className='w-full'>
            <div className='lg:w-2/4 md:w-4/5 w-11/12  w-full m-auto hero-content text-center'>
              <div className='w-9/12 m-auto'>
                <Link to='/'> <img className='m-auto w-20 mb-5' src='img/home-page-img/logo.svg' /></Link>
                <h1 className='lg:mb-5 md:mb-3 mb-2 lg:text-2xl md:text-xl text-base'>Free Somali music On AfroMelodiez</h1>
                <hr style={{ height: "5px", maxWidth: "600px", margin: "auto", background: "#1ED760", marginBottom: "20px" }} />
                <h2 className='lg:mb-5 md:mb-3 mb-2 lg:text-2xl md:text-xl text-base'>Contact Us</h2>

                <div className='w-6/6 m-auto'>
                  <div className='social-links text-left'>
                  <div className='left-icons flex items-center gap-6'>
                    <a href='https://mobile.twitter.com/home' target="blank" className="md:w-11 w-7"><img src='/img/home-page-img/twitter.svg'/>Contact Us on Twitter</a>
                    <a href='https://www.facebook.com/AfroMelodiez-107666121785871/' target="blank" className="md:w-11 w-7"><img src='/img/home-page-img/fb.svg'/>Contact Us on Facebook</a>
                    <a href='https://instagram.com/afromelodiez?igshid=YmMyMTA2M2Y=' target="blank" className="md:w-11 w-7"><img src='/img/home-page-img/insta.svg'/>Contact Us on Instagram</a>
                </div>
                    {/* 
                            <Link to className="flex lg:text-xl md:text-base text-sm items-center bg-black text-white  justify-center m-auto mb-5 py-2 lg:px-12 md:px-8 px-5 rounded-full text-white"><img className='mr-5 w-6' src='img/home-page-img/fb.png' /> Continue with Facebook</Link>
                            <Link to className='flex lg:text-xl md:text-base text-sm items-center bg-black text-white justify-center m-auto mb-5 py-2 lg:px-12 md:px-8 px-5 rounded-full text-white ' href=''><img className='mr-5 w-6' src='img/home-page-img/google.png' />Continue with Google</Link>
                            <div className='google-icon flex lg:text-xl md:text-base text-sm items-center bg-black text-white justify-center m-auto mb-5 lg:px-12 md:px-8 px-5 rounded-full text-white '> 
                              <GoogleLogin  disabledStyle={false}style={false}
                            clientId="496574926292-p8ih2pd4galemf4sbstag76hmmv2ptu1.apps.googleusercontent.com"
                            buttonText="Continue with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                         />
                        </div>
                      
                            <Link to className='flex lg:text-xl md:text-base text-sm items-center bg-black text-white  justify-center m-auto mb-5 py-2 lg:px-12 md:px-8 px-5 rounded-full text-white ' href=''><img className='mr-5 h-6' src='img/home-page-img/apple.png' />Continue with Apple</Link> */}

                    <div className='or-head flex items-center '>
                      <hr style={{ height: "1px", width: "100px", marginLeft: "auto", background: "#5B5B5B", marginRight: "10px" }} /><p className='text-base'>OR</p><hr style={{ height: "1px", width: "100px", marginRight: "auto", background: "#5B5B5B", marginLeft: "10px" }} />
                    </div>
                  </div>
                </div> 
              </div>
              <div className=' rounded-3xl lg:p-12 md:p-10 p-6 mb-12' style={{ boxShadow: "0px 0px 39px 6px #C4C4C4" }}>
                <form className='text-left'>
                 <p className='mb-3 text-xl' style={{color:"red"}}>{allFieldErr}</p>
                  
                  
                  <lable> Name:</lable>
                  <input type="text" value={contactUsForm.name} name="name" class="lg:text-sm md:text-sm text-sm mt-0 mb-5 focus:outline-none lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Enter your name" onChange={handleInput} />
                  {/* onChange={handleName} */}
                  <lable>Company Name:</lable>
                  <input type="text" value={contactUsForm.company_name} name="company_name" class="lg:text-sm md:text-sm focus:outline-none text-sm mt-0 mb-5  lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Enter a company name" onChange={handleInput} />
                  {/* onChange={handleCompanyName} */}
                  <lable> Email:</lable>
                  <input type="email" value={contactUsForm.email} name="email" class="lg:text-sm md:text-sm text-sm mt-0 mb-5 focus:outline-none lg:py-2 py-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Enter your email" onChange={handleInput} />
                  {/* onChange={handleEmailChange} */}
                  {/* <input type="email" value={contactUsForm.confirmEmail} name="confirmEmail" onChange={handleInput} class="lg:text-2xl md:text-xl text-base mt-0 mb-5  lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " placeholder="Confirm your email again"/> */}
                  <lable>Message:</lable>

                  <textarea id="" value={contactUsForm.message} name="message" class="lg:text-sm md:text-sm text-sm mt-0 mb-5 focus:outline-none lg:py-2 py-1  block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black
                  " rows="4" cols="50" placeholder='Enter your Message' onChange={handleInput}></textarea>
                  {/* onChange={handleNameChange} */}
                  <div className='text-center mt-12'>
                    <button className='py-3 px-16 rounded-full lg:text-xl text-base text-white inline-block' style={{ background: "#1ED760" }} href='' onClick={(e) => handleSubmit(e)} >Submit</button>
                    {/* onClick={(e)=>handleSubmit(e)}  */}
                  </div>
                </form>

              </div>


              <div className='login-back-head'>
                <p className='lg:text-xl md:text-base text-sm font-bold '>Have an account?<Link to='/signin' style={{ color: "#1ED760", marginLeft: "15px" }} >Login</Link></p>

              </div>

            </div>
          </div>

        </div>
      </section>

    </div>
  )
}




