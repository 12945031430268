import React, { useContext} from 'react'
import PlayerContext from './playerContext'
import Player from '../components/Musicplayer/player1'
  


const StartPlayer = () => {

    const playerctx=useContext(PlayerContext)
   var play;
    
   if(playerctx.index===-1 ){
   if(playerctx.musicList){
   play=<Player allsongs={playerctx.musicList} />
   
   }else{
   play=''
   }
   }
   else{
   play=<Player allsongs={playerctx.musicList} setPlaying={playerctx.playing} setIndex={playerctx.indexset}  index={playerctx.index}/> 
   }
    
 console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaa',playerctx.musicList)
  return (
   <div>{play}</div>
  )
}

export default StartPlayer