import React, { useState,useContext,useEffect } from 'react'
import axios from 'axios';
import {useParams } from "react-router-dom"
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PlayerContext from '../playerContext/playerContext';
import Load from "../components/loader/loader" ;
import '../App.css'
// import { SpaceBar } from '@material-ui/icons';

const convertminutes=(totalSeconds)=>{
    var minutes = Math.floor(totalSeconds / 60);
      var seconds = totalSeconds % 60;
       if (seconds<10){
          seconds=`0${seconds}`
       }
   
   var time=`${minutes}:${seconds}`
    return time;
   }
function Song() {
    const {id}=useParams()
    
    const tokenctx=useContext(PlayerContext)

   const[loading,setLoading]=useState(false)
   const[songs,setSongs]=useState([])
   const[name,setName]=useState()
   
    const Music = async() => {
        const token=tokenctx.token
          setLoading(true);
          tokenctx.load(true)
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/song_by_id/${id}`,{ headers: { "Authorization": `Bearer ${token}` } });
         console.log('abcdddddefffffff',res.data.Message)
         
          setSongs(res.data.Message)
          setName(res.data.Message.artist_id.name)
          setLoading(false);
          tokenctx.load(false)
        
        }
        console.log('abcddffffff',songs)
        const imageClick = () => {
          
            tokenctx.startPlay(songs,-1,-1,-1)
        
            
          }
        useEffect(() => {
            
        document.title = `Song | AfroMelodiez`
          Music();
           
         }, [])


    return (
        <div>
        {loading && <div className='loader'><Load /></div>}
        {!loading && <div>
          <section>
            
           <div className='container m-auto mt-8 mb-12 px-5'>
                    <div className='song-head flex flex-wrap '>
                        <div className='w-44'>
                            <img className='rounded-xl m-auto mb-4' src={`${process.env.REACT_APP_API_URL}/storage/song/images/${songs.song_image}`} />
                           

                
                        </div>
                        <div className='md:w-2/4 w-full px-5'>
                            <div className='flex flex-wrap h-full'>
                                <div className='flex gap-10 w-full'>
                                    <p className=' text-2xl font-bold mb-5'>{songs.name}</p>
                                    
                                </div>
                                <h3 className='text-xl font-bold mb-2'>{songs.album}</h3>
                                <div>
                                    <ul className=''>
                                       
                                        <li className="flex align-center"><span className="pr-3">Artist Name:</span><p className='text-base mb-5'>{name}</p></li>
                                        <li><span className="pr-3">Duration:</span><p className='text-base mb-5 inline-block rounded-full border border-grey-600 px-5'>{convertminutes(Number(songs.duration))}</p></li>
                                    </ul>
                                  
                                </div>
                                <div className='flex items-center gap-6 mb-0 w-full '>
                                <button style ={{ background: "#1ED760" ,cursor:'pointer'}}onClick={() => imageClick()} className='py-2 px-5 rounded-full lg:text-xl text-base text-white inline-block' >
                                <PlayArrowIcon />  Play</button>
                                     <button className='song-fn-btn  py-2 px-5 rounded-md lg:text-xl text-base text-white'>
                                     <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 30.866 28.2">
  <g id="Expanded" transform="translate(0.5 -2.787)">
    <g id="Group_36137" data-name="Group 36137" transform="translate(0 3.287)">
      <path id="Path_37768" data-name="Path 37768" d="M14.933,30.487a.62.62,0,0,1-.312-.084,45.437,45.437,0,0,1-7.266-5.462C2.475,20.428,0,15.969,0,11.687c0-5.771,3.907-8.4,7.537-8.4a8.035,8.035,0,0,1,7.4,5.12,8.035,8.035,0,0,1,7.4-5.12c3.63,0,7.537,2.629,7.537,8.4,0,4.282-2.475,8.741-7.355,13.254A45.393,45.393,0,0,1,15.245,30.4.62.62,0,0,1,14.933,30.487ZM7.537,4.531c-3.031,0-6.293,2.239-6.293,7.156,0,8.626,11.619,16.175,13.689,17.451C17,27.862,28.622,20.307,28.622,11.687c0-4.916-3.262-7.156-6.293-7.156-2.783,0-5.961,1.905-6.785,6.162a.622.622,0,0,1-1.222,0C13.5,6.437,10.32,4.531,7.537,4.531Z" transform="translate(0 -3.287)" fill="#fff" stroke="#fff" stroke-width="1"/>
    </g>
  </g>
</svg>
                                     </button>
                                    {/* <button className='song-fn-btn  py-2 px-5 rounded-md lg:text-xl text-base text-white'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 28.205 28.201">
  <path id="_1904668_connection_media_document_network_share_icon" data-name="1904668_connection_media_document_network_share_icon" d="M22.3,17.432a4.888,4.888,0,0,0-4.779,3.862L8.86,16.431a4.888,4.888,0,0,0,0-5.646L17.524,5.92a4.888,4.888,0,1,0-.094-1.409L7.967,9.824a4.888,4.888,0,1,0,0,7.57l9.463,5.313A4.888,4.888,0,1,0,22.3,17.432Zm0-16.151A3.613,3.613,0,1,1,18.69,4.894,3.613,3.613,0,0,1,22.3,1.281ZM2.327,16.157a3.613,3.613,0,0,1,5.1-5.1h0a3.613,3.613,0,0,1-5.1,5.1ZM22.3,25.932a3.613,3.613,0,1,1,3.613-3.613A3.613,3.613,0,0,1,22.3,25.932Z" transform="translate(0.514 0.492)" fill="#fff" stroke="#fff" stroke-width="1"/>
</svg>
                                    </button>
                                     <button className='song-fn-btn  py-2 px-5 rounded-md lg:text-xl text-base text-white'>
                                     <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 48 41.414">
  <g id="Group_22" data-name="Group 22" transform="translate(-2938 -1523)">
    <g id="Expanded" transform="translate(2938 1519)">
      <g id="Group_21" data-name="Group 21">
        <g id="Group_17" data-name="Group 17">
          <path id="Path_9" data-name="Path 9" d="M6,45.414V36H3a3,3,0,0,1-3-3V7A3,3,0,0,1,3,4H45a3,3,0,0,1,3,3V33a3,3,0,0,1-3,3H15.414ZM3,6A1,1,0,0,0,2,7V33a1,1,0,0,0,1,1H8v6.586L14.586,34H45a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1Z" fill="#fff"/>
        </g>
      </g>
    </g>
    <rect id="Rectangle_5" data-name="Rectangle 5" width="22" height="2" rx="1" transform="translate(2951 1537)" fill="#fff"/>
  </g>
</svg>
                                     </button> */}
                                      {/* <button className='song-fn-btn  py-2 px-5 rounded-md lg:text-xl text-base text-white'>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 29 28.2">
  <g id="_1904659_arrow_backup_down_download_save_icon" data-name="1904659_arrow_backup_down_download_save_icon" transform="translate(0.5 -0.3)">
    <path id="Path_37766" data-name="Path 37766" d="M38.746,14.82h0a.477.477,0,0,0,.07.057l.038.021a.277.277,0,0,0,.094.038l.04.011h0a.477.477,0,0,0,.185,0l.041-.013.048-.014a.477.477,0,0,0,.048-.025l.033-.019a.477.477,0,0,0,.073-.06L43.7,10.537a.477.477,0,1,0-.67-.67l-3.468,3.463V.477a.477.477,0,0,0-.955,0V13.331L35.138,9.867a.477.477,0,0,0-.67.67Z" transform="translate(-25.068 0.8)" fill="#fff" stroke="#fff" stroke-width="1"/>
    <path id="Path_37767" data-name="Path 37767" d="M27.344,73a.656.656,0,0,0-.656.656V93.469a1.969,1.969,0,0,1-1.969,1.969H3.281a1.969,1.969,0,0,1-1.969-1.969V73.656a.656.656,0,0,0-1.312,0V93.469A3.281,3.281,0,0,0,3.281,96.75H24.719A3.281,3.281,0,0,0,28,93.469V73.656A.656.656,0,0,0,27.344,73Z" transform="translate(0 -68.75)" fill="#fff" stroke="#fff" stroke-width="1"/>
  </g>
</svg>
                                      </button> */}
                                </div>
                              
                            </div>
                        

                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container m-auto mb-12 px-5'>
                    <div className='singer-song-list'>
                        <table class="table-auto" style={{ width: "100%" }}>
                            <thead>
                                
                              
                            </thead>
                            <tbody>
                                
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>


        </div>}
        </div>
    )
}

export default Song