
import React, { useEffect, useState,useContext,useRef} from "react";
import Modal from 'react-modal';

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import axios from "axios";
import { RHAP_UI } from "react-h5-audio-player";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//   import { faDownload, faPlus } from '@fortawesome/free-solid-svg-icons'
  import { useNavigate , useParams , Link} from 'react-router-dom';


import { Navigate, useLocation } from 'react-router-dom';
// import demo from '../../images2/demo.jpg'
// import CallPlayer from "../../playerContext/callPlayer";
// import Button from "@material-ui/core/Button";
// import Tooltip from "@material-ui/core/Tooltip";
import PlayerContext from "../../playerContext/playerContext";

// import Myplaylist from "../listing/Myplaylist";
// import { formatRelative } from "date-fns";

export default function Player(props) {
  const { id } = useParams()

  const navigate = useNavigate();
    const [modalIsOpen, setIsOpen]=useState(false)
    const[playlist,setPlayList]=useState([])
    const[filled, setFilled]=useState(false)
    const audioPlayer = useRef();
    const playerctx= useContext(PlayerContext)
    let autoplay=true

   const token=playerctx.token
   const userId=playerctx.userId
   const isLoggedin=playerctx.isloggedIn

     console.log("playinga",props);
    const [currentMusic, setCurrentMusic] = useState(props.music);
    const [heart, setheart]= useState(false);
    const [songs,setSongs]=useState(true)
    const location = useLocation
    console.log('consoling list',props.allsongs[props.index])



    let newfollower = {
      artistID: id,
      userID: playerctx.userId,
  }
    const Songs = async() => {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/follow_artist`, newfollower, { headers: { "Authorization": `Bearer ${token}` } });
    console.log('musiccccccccccc',response)
    

if(response.data.success==true){

  response.data.data.map((item)=>{
    item.is_liked.map((data)=>{
      if(data){
        setheart(true)
      }
      else{
        setheart(false)
      }
    })
  })
}

    }
useEffect(()=>{
  Songs()
    },[]);


    const Music = async() => {
    
    
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/user_playlist_get/${userId}`,{ headers: { "Authorization": `Bearer ${token}` } });
    console.log('musiccccccccccc',res)
    
     setPlayList(res.data.Data)
     console.log("asdfasdada",res.data.Data)
    
    }

   
    const handleClickPrevious = () => {
      
        if(props.index===0 || props.index===undefined){
            setSongs(false)
        // alert('no more songs')
        return
        }
        setSongs(true)
        playerctx.previous()
        setCurrentMusic(props.allsongs[props.index])
      
    };

      let subtitle;
   
;
    function openModal() {
        Music()
        
        setIsOpen(true);
      }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
   
    }
  
    function closeModal() {
      setIsOpen(false);
    }
  
 
    const handleClickNext = () => {
       if(props.index+1===props.allsongs.length || props.index===undefined){
      setSongs(false)
        return
       }
       setSongs(true)
       playerctx.next()
        setCurrentMusic(props.allsongs[props.index])
    };
    const songadd = async (user) => {
        console.log('tabledataaaaaaaa',user)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/user_song_like`,user,{
         headers: { "Authorization": `Bearer ${token}` }} );
      console.log('tabledata',response.data)
      setFilled(true)
        
      }
      const songremove = async (user) => {
        console.log('tabledataaaaaaaa',user)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/user_song_unlike`,user,{
         headers: { "Authorization": `Bearer ${token}` }} );
         setFilled(false)
      console.log('tabledataaaaaaaa',response.data)
        
      }
    
    const handlelike=(song)=>{
        setheart(true)
        const user={
            userID:playerctx.userId,
            songID:song.id
        }
        console.log('tabledata',song)
        console.log('tabledata',userId,song.id)
        songadd(user)
        
       

    };
    const handleunlike=(song)=>{
        setheart(false)
        const user={
            userID:userId,
            songID:song.id
        }
        songremove(user)
    };
 
    // const condition=props.allsongs[props.index].song_image;
    console.log("eegtetgedrtgeg",props)
    
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width:"50%",
          height:"50%",
          backgroundColor:"#333",
          color:"#fff",
          position:"relative",
          padding: "0px 20px 20px 20px",
        },
      };
      
      Modal.setAppElement(document.getElementById('root'));
    
    
    var img,audiosrc,header,like;
  //   if(!props){
  //  return(

  //  )
  //   }

   
    if(props.index===undefined ){
     playerctx.pause()
     
        img=props.allsongs.song_image
      audiosrc=props.allsongs.song
      console.log('aaaaaaaaaa')
      header=props.allsongs.name
      like=props.allsongs
     playerctx.play()
    
    }
    else {
    
     img=props.allsongs[props.index].song_image
    
     audiosrc=props.allsongs[props.index].song
        // show= <CallPlayer music={props.allsongs}  />
         header=props.allsongs[props.index].name
         like=props.allsongs[props.index]
        autoplay=false
        
    }
  const addSongToPlaylist=async (playlist,song)=>{
    var playlist={
      user_id:playerctx.userId,
      playlistID:playlist.id,
      songID:song.id

      
    }
    
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/user_add_song_playlist`,playlist ,{ headers: { "Authorization": `Bearer ${token}` } });
    console.log('sdfdsgf',res)
    
 

  }
    
    return(
    
        
            <div id="player" className="fixed bg-black bottom-0  left-0 right-0" maxWidth="sm">
            <div>
          
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
            <div className="select_playlist">
            <p>Please Select A Playlist</p>
            <button onClick={closeModal}>X</button>
            </div>
           
            <Link to="/add-playlist" className="flex items-center gap-3"> <img src="/img/playerIcon/add-playlist.svg"/><button className=' text-base inline-block' href=''>Add playlist</button></Link>
            {playlist && playlist.map((playlist)=>( <div onClick={()=>addSongToPlaylist(playlist,like)} className='playlist_box'>
            <div className="modal-img">
            <img src='/img/playlist.webp'/>
            </div>
           {console.log(playlist)}
            <span>{playlist. playlist_name}</span>
          
            </div>))}
            </Modal>
          </div>
      {audiosrc &&   <div className="flex items-center relative" style={{ borderRadius: "1rem",boxShadow: "0 0 3px 0 rgb(0 0 0 / 20%)"}}>
    <div className="flex items-center img_sec_fr_plyr w-16 h-12 ml-5">
       {img &&<img style={{ width: "100%", height: "100%",borderRadius:"7px" }} src={`${process.env.REACT_APP_API_URL}/storage/song/images/${img}`} />}
    </div>
{console.log("sfcdsfcds",`${process.env.REACT_APP_API_URL}/storage/song/${audiosrc}`)} 
      
     <AudioPlayer
        
       autoPlay={autoplay}
       autoPlayAfterSrcChange={true}
        src={`${process.env.REACT_APP_API_URL}/storage/song/${audiosrc}`}
        onPlay={(e) => playerctx.play(e)}
        onPause={(e)=> playerctx.pause(e)}
        showSkipControls={true}
        showJumpControls={false}
        header={`${header}`}
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
        onEnded={handleClickNext}
        customVolumeControls={
            [
             
                
                
            
//               <button style ={{cursor:'pointer'}} className="player-f-btn md:block hidden ">
//               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 29 28.2">
//   <g id="_1904659_arrow_backup_down_download_save_icon" data-name="1904659_arrow_backup_down_download_save_icon" transform="translate(0.5 -0.3)">
//     <path id="Path_37766" data-name="Path 37766" d="M38.746,14.82h0a.477.477,0,0,0,.07.057l.038.021a.277.277,0,0,0,.094.038l.04.011h0a.477.477,0,0,0,.185,0l.041-.013.048-.014a.477.477,0,0,0,.048-.025l.033-.019a.477.477,0,0,0,.073-.06L43.7,10.537a.477.477,0,1,0-.67-.67l-3.468,3.463V.477a.477.477,0,0,0-.955,0V13.331L35.138,9.867a.477.477,0,0,0-.67.67Z" transform="translate(-25.068 0.8)" fill="#fff" stroke="#fff" stroke-width="1"/>
//     <path id="Path_37767" data-name="Path 37767" d="M27.344,73a.656.656,0,0,0-.656.656V93.469a1.969,1.969,0,0,1-1.969,1.969H3.281a1.969,1.969,0,0,1-1.969-1.969V73.656a.656.656,0,0,0-1.312,0V93.469A3.281,3.281,0,0,0,3.281,96.75H24.719A3.281,3.281,0,0,0,28,93.469V73.656A.656.656,0,0,0,27.344,73Z" transform="translate(0 -68.75)" fill="#fff" stroke="#fff" stroke-width="1"/>
//   </g>
// </svg>
//               </button>,
              heart==false?
               <button style ={{cursor:'pointer' }} className="player-f-btn  ">

<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 30.866 28.2" onClick={(e)=>handlelike(like)}>
<g id="Expanded" transform="translate(0.5 -2.787)">
  <g id="Group_36137" data-name="Group 36137" transform="translate(0 3.287)">
    <path id="Path_37768" data-name="Path 37768" d="M14.933,30.487a.62.62,0,0,1-.312-.084,45.437,45.437,0,0,1-7.266-5.462C2.475,20.428,0,15.969,0,11.687c0-5.771,3.907-8.4,7.537-8.4a8.035,8.035,0,0,1,7.4,5.12,8.035,8.035,0,0,1,7.4-5.12c3.63,0,7.537,2.629,7.537,8.4,0,4.282-2.475,8.741-7.355,13.254A45.393,45.393,0,0,1,15.245,30.4.62.62,0,0,1,14.933,30.487ZM7.537,4.531c-3.031,0-6.293,2.239-6.293,7.156,0,8.626,11.619,16.175,13.689,17.451C17,27.862,28.622,20.307,28.622,11.687c0-4.916-3.262-7.156-6.293-7.156-2.783,0-5.961,1.905-6.785,6.162a.622.622,0,0,1-1.222,0C13.5,6.437,10.32,4.531,7.537,4.531Z" transform="translate(0 -3.287)" fill="#fff" stroke="#fff" stroke-width="1"/>
  </g>
</g>
</svg>
   
  </button>:
<button style ={{cursor:'pointer',color:'red'}} className="player-f-btn ">
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 50.669 47.417"    onClick={(e)=>handleunlike(like)}>
  <g id="_211673_heart_icon" data-name="211673_heart_icon" transform="translate(-47.975 -63.925)">
    <path id="Path_37764" data-name="Path 37764" d="M94.488,67.835a12.143,12.143,0,0,0-17.829,0l-3.349,3.518L69.96,67.823a12.143,12.143,0,0,0-17.829,0,15.546,15.546,0,0,0,0,21.16l21.178,22.358L94.488,89A15.546,15.546,0,0,0,94.488,67.835Z" fill="#00d35e"/>
  </g>
</svg>

</button>
              ,
//               <button style ={{cursor:'pointer'}} className="player-f-btn md:block hidden ">
//             <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 28.205 28.201">
//   <path id="_1904668_connection_media_document_network_share_icon" data-name="1904668_connection_media_document_network_share_icon" d="M22.3,17.432a4.888,4.888,0,0,0-4.779,3.862L8.86,16.431a4.888,4.888,0,0,0,0-5.646L17.524,5.92a4.888,4.888,0,1,0-.094-1.409L7.967,9.824a4.888,4.888,0,1,0,0,7.57l9.463,5.313A4.888,4.888,0,1,0,22.3,17.432Zm0-16.151A3.613,3.613,0,1,1,18.69,4.894,3.613,3.613,0,0,1,22.3,1.281ZM2.327,16.157a3.613,3.613,0,0,1,5.1-5.1h0a3.613,3.613,0,0,1-5.1,5.1ZM22.3,25.932a3.613,3.613,0,1,1,3.613-3.613A3.613,3.613,0,0,1,22.3,25.932Z" transform="translate(0.514 0.492)" fill="#fff" stroke="#fff" stroke-width="1"/>
// </svg>
//               </button>,
            <Link to="/my-playlist">  <button style ={{cursor:'pointer'}}className="player-f-btn md:block hidden ">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 34.049 28.199">
  <g id="_9042900_playlist_icon" data-name="9042900_playlist_icon" transform="translate(-1.25 -4.75)">
    <path id="Path_37752" data-name="Path 37752" d="M2,11H21.2" transform="translate(0 2.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    <path id="Path_37753" data-name="Path 37753" d="M2,17H14.856" transform="translate(0 4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    <path id="Path_37769" data-name="Path 37769" d="M2,17H12.138" transform="translate(0 12.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    <path id="Path_37754" data-name="Path 37754" d="M2,5H25.279" transform="translate(0 0.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    <path id="Path_37755" data-name="Path 37755" d="M25.16,21.08A4.08,4.08,0,1,1,21.08,17,4.08,4.08,0,0,1,25.16,21.08Z" transform="translate(4.199 7.04)" fill="#fff"/>
    <path id="Path_37756" data-name="Path 37756" d="M25.01,32.695a4,4,0,1,1-4-4A4,4,0,0,1,25.01,32.695Zm0,0V11.6a1.6,1.6,0,0,1,1.6-1.6h3.738" transform="translate(4.199 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
  </g>
</svg>
              </button></Link>,
              <button style ={{cursor:'pointer'}}  onClick={openModal} className="player-f-btn md:block hidden ">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 28.199 28.2">
  <g id="Layer_2" data-name="Layer 2" transform="translate(-3 -3)">
    <path id="Path_37764" data-name="Path 37764" d="M30.156,30.7H4.044a.544.544,0,0,1-.544-.544V4.044A.544.544,0,0,1,4.044,3.5H30.156a.544.544,0,0,1,.544.544V30.156A.544.544,0,0,1,30.156,30.7ZM4.588,29.612H29.612V4.588H4.588Z" transform="translate(0 0)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="bevel" stroke-width="1"/>
    <path id="Path_37765" data-name="Path 37765" d="M23.1,16.588H10.044a.544.544,0,0,1,0-1.088H23.1a.544.544,0,1,1,0,1.088Z" transform="translate(0.528 1.056)" fill="#fff" stroke="#fff" stroke-width="1"/>
    <path id="Path_37766" data-name="Path 37766" d="M16.044,23.644A.544.544,0,0,1,15.5,23.1V10.044a.544.544,0,0,1,1.088,0V23.1A.544.544,0,0,1,16.044,23.644Z" transform="translate(1.056 0.528)" fill="#fff" stroke="#fff" stroke-width="1"/>
  </g>
</svg>
              </button>,
              
              
              
              
            ]}
       
            ref={audioPlayer}
        customProgressBarSection={
            [
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.CURRENT_TIME,
              <div>/</div>,
              RHAP_UI.DURATION
            ]
          }
        
          
    
/>
</div>}

</div>


    
     
        
    );
}








// import React, { useEffect, useState,useContext,useRef} from "react";
// import Modal from 'react-modal';

// import AudioPlayer from "react-h5-audio-player";
// import "react-h5-audio-player/lib/styles.css";
// import axios from "axios";
// import { RHAP_UI } from "react-h5-audio-player";
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// //   import { faDownload, faPlus } from '@fortawesome/free-solid-svg-icons'
//   import { useNavigate , useParams , Link} from 'react-router-dom';


// import { Navigate, useLocation } from 'react-router-dom';
// // import demo from '../../images2/demo.jpg'
// // import CallPlayer from "../../playerContext/callPlayer";
// // import Button from "@material-ui/core/Button";
// // import Tooltip from "@material-ui/core/Tooltip";
// import PlayerContext from "../../playerContext/playerContext";

// // import Myplaylist from "../listing/Myplaylist";
// // import { formatRelative } from "date-fns";

// export default function Player(props) {
//   const { id } = useParams()

//   const navigate = useNavigate();
//     const [modalIsOpen, setIsOpen]=useState(false)
//     const[playlist,setPlayList]=useState([])
//     const[filled, setFilled]=useState(false)
//     const audioPlayer = useRef();
//     const playerctx= useContext(PlayerContext)
//     let autoplay=true

//    const token=playerctx.token
//    const userId=playerctx.userId
//    const isLoggedin=playerctx.isloggedIn
//   //  const liked=playerctx.liked

//      console.log("playinga",props);
//     const [currentMusic, setCurrentMusic] = useState(props.music);
//     const [heart, setheart]= useState(false);
//     const [songs,setSongs]=useState(true)
//     const location = useLocation
//     console.log('consoling list',props.allsongs[props.index])



//     let newfollower = {
//       artistID: id,
//       userID: playerctx.userId,
//   }
//     const Songs = async() => {
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/follow_artist`, newfollower, { headers: { "Authorization": `Bearer ${token}` } });
//     console.log('musiccccccccccc',response)
    

// // if(response.data.success==true){

// //   response.data.data.map((item)=>{
// //     item.is_liked.map((data)=>{
// //       if(data==userId){
// //         isLiked=true
// //         // setheart(true)
// //       }
// //       else{
// //         isLiked=false
// //         // setheart(false)
// //       }
// //     })
// //   })
// // }

//     }
// useEffect(()=>{
//   Songs()
//     },[]);


//     const Music = async() => {
    
    
//       const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/user_playlist_get/${userId}`,{ headers: { "Authorization": `Bearer ${token}` } });
//     console.log('musiccccccccccc',res)
    
//      setPlayList(res.data.Data)
//      console.log("asdfasdada",res.data.Data)
    
//     }

   
//     const handleClickPrevious = () => {
      
//         if(props.index===0 || props.index===undefined){
//             setSongs(false)
//         // alert('no more songs')
//         return
//         }
//         setSongs(true)
//         playerctx.previous()
//         setCurrentMusic(props.allsongs[props.index])
      
//     };

//       let subtitle;
   
// ;
//     function openModal() {
//         Music()
        
//         setIsOpen(true);
//       }
  
//     function afterOpenModal() {
//       // references are now sync'd and can be accessed.
   
//     }
  
//     function closeModal() {
//       setIsOpen(false);
//     }
  
 
//     const handleClickNext = () => {
//        if(props.index+1===props.allsongs.length || props.index===undefined){
//       setSongs(false)
//         return
//        }
//        setSongs(true)
//        playerctx.next()
//         setCurrentMusic(props.allsongs[props.index])
//     };
//     const songadd = async (user) => {
//         console.log('tabledataaaaaaaa',user)
//         const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/user_song_like`,user,{
//          headers: { "Authorization": `Bearer ${token}` }} );
//       console.log('tabledata',response.data)
//       setFilled(true)
        
//       }
//       const songremove = async (user) => {
//         console.log('tabledataaaaaaaa',user)
//         const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/user_song_unlike`,user,{
//          headers: { "Authorization": `Bearer ${token}` }} );
//          setFilled(false)
         
//       console.log('tabledataaaaaaaa',response.data)
        
//       }
    
//     const handlelike=(song)=>{
//       isLiked=true
//         // setheart(true)
//         const user={
//             userID:playerctx.userId,
//             songID:song.id
//         }
//         console.log('tabledata',song)
//         console.log('tabledata',userId,song.id)
//         songadd(user)
        
       

//     };
//     const handleunlike=(song)=>{
//         // setheart(false)
//         isLiked=false
//         const user={
//             userID:userId,
//             songID:song.id
//         }
//         songremove(user)
//     };
 
//     // const condition=props.allsongs[props.index].song_image;
//     console.log("eegtetgedrtgeg",props)
    
//     const customStyles = {
//         content: {
//           top: '50%',
//           left: '50%',
//           right: 'auto',
//           bottom: 'auto',
//           marginRight: '-50%',
//           transform: 'translate(-50%, -50%)',
//           width:"50%",
//           height:"50%",
//           backgroundColor:"#333",
//           color:"#fff",
//           position:"relative",
//           padding: "0px 20px 20px 20px",
//         },
//       };
      
//       Modal.setAppElement(document.getElementById('root'));
    
    
//     var img,audiosrc,header,like,isLiked;
//   //   if(!props){
//   //  return(

//   //  )
//   //   }

   
//     if(props.index===undefined ){
//      playerctx.pause()
     
//         img=props.allsongs.song_image
//       audiosrc=props.allsongs.song
   
//       header=props.allsongs.name
//       like=props.allsongs
//       isLiked=props.allsongs.is_liked
//       console.log('aaaaaaaaaa',isLiked)
//      playerctx.play()
    
//     }
//     else {
    
//      img=props.allsongs[props.index].song_image
    
//      audiosrc=props.allsongs[props.index].song
//         // show= <CallPlayer music={props.allsongs}  />
//          header=props.allsongs[props.index].name
//          isLiked=props.allsongs[props.index].is_liked
//          like=props.allsongs[props.index]
//         autoplay=false
        
//     }
//   const addSongToPlaylist=async (playlist,song)=>{
//     var playlist={
//       user_id:playerctx.userId,
//       playlistID:playlist.id,
//       songID:song.id

      
//     }
    
//       const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/user_add_song_playlist`,playlist ,{ headers: { "Authorization": `Bearer ${token}` } });
//     console.log('sdfdsgf',res)
    
 

//   }
    
//     return(
    
        
//             <div className="fixed bg-black bottom-0  left-0 right-0" maxWidth="sm">
//             <div>
          
//             <Modal
//               isOpen={modalIsOpen}
//               onAfterOpen={afterOpenModal}
//               onRequestClose={closeModal}
//               style={customStyles}
//               contentLabel="Example Modal"
//             >
//             <div className="select_playlist">
//             <p>Please Select A Playlist</p>
//             <button onClick={closeModal}>X</button>
//             </div>
           
//             <Link to="/add-playlist" className="flex items-center gap-3"> <img src="/img/playerIcon/add-playlist.svg"/><button className=' text-base inline-block' href=''>Add playlist</button></Link>
//             {playlist && playlist.map((playlist)=>( <div onClick={()=>addSongToPlaylist(playlist,like)} className='playlist_box'>
//             <div className="modal-img">
//             <img src='/img/playlist.webp'/>
//             </div>
//            {console.log(playlist)}
//             <span>{playlist. playlist_name}</span>
          
//             </div>))}
//             </Modal>
//           </div>
//       {audiosrc &&   <div className="flex items-center relative" style={{ borderRadius: "1rem",boxShadow: "0 0 3px 0 rgb(0 0 0 / 20%)"}}>
//     <div className="flex items-center img_sec_fr_plyr w-16 h-12 ml-5">
//        {img &&<img style={{ width: "100%", height: "100%",borderRadius:"7px" }} src={`${process.env.REACT_APP_API_URL}/storage/song/images/${img}`} />}
//     </div>
// {console.log("sfcdsfcds",`${process.env.REACT_APP_API_URL}/storage/song/${audiosrc}`)} 
      
//      <AudioPlayer
        
//        autoPlay={autoplay}
//        autoPlayAfterSrcChange={true}
//         src={`${process.env.REACT_APP_API_URL}/storage/song/${audiosrc}`}
//         onPlay={(e) => playerctx.play(e)}
//         onPause={(e)=> playerctx.pause(e)}
//         showSkipControls={true}
//         showJumpControls={false}
//         header={`${header}`}
//         onClickPrevious={handleClickPrevious}
//         onClickNext={handleClickNext}
//         onEnded={handleClickNext}
//         customVolumeControls={
//             [
             
                
                
            
// //               <button style ={{cursor:'pointer'}} className="player-f-btn md:block hidden ">
// //               <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 29 28.2">
// //   <g id="_1904659_arrow_backup_down_download_save_icon" data-name="1904659_arrow_backup_down_download_save_icon" transform="translate(0.5 -0.3)">
// //     <path id="Path_37766" data-name="Path 37766" d="M38.746,14.82h0a.477.477,0,0,0,.07.057l.038.021a.277.277,0,0,0,.094.038l.04.011h0a.477.477,0,0,0,.185,0l.041-.013.048-.014a.477.477,0,0,0,.048-.025l.033-.019a.477.477,0,0,0,.073-.06L43.7,10.537a.477.477,0,1,0-.67-.67l-3.468,3.463V.477a.477.477,0,0,0-.955,0V13.331L35.138,9.867a.477.477,0,0,0-.67.67Z" transform="translate(-25.068 0.8)" fill="#fff" stroke="#fff" stroke-width="1"/>
// //     <path id="Path_37767" data-name="Path 37767" d="M27.344,73a.656.656,0,0,0-.656.656V93.469a1.969,1.969,0,0,1-1.969,1.969H3.281a1.969,1.969,0,0,1-1.969-1.969V73.656a.656.656,0,0,0-1.312,0V93.469A3.281,3.281,0,0,0,3.281,96.75H24.719A3.281,3.281,0,0,0,28,93.469V73.656A.656.656,0,0,0,27.344,73Z" transform="translate(0 -68.75)" fill="#fff" stroke="#fff" stroke-width="1"/>
// //   </g>
// // </svg>
// //               </button>,
//              isLiked==false ?
//                <button style ={{cursor:'pointer' }} className="player-f-btn  ">

// <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 30.866 28.2" onClick={(e)=>handlelike(like)}>
// <g id="Expanded" transform="translate(0.5 -2.787)">
//   <g id="Group_36137" data-name="Group 36137" transform="translate(0 3.287)">
//     <path id="Path_37768" data-name="Path 37768" d="M14.933,30.487a.62.62,0,0,1-.312-.084,45.437,45.437,0,0,1-7.266-5.462C2.475,20.428,0,15.969,0,11.687c0-5.771,3.907-8.4,7.537-8.4a8.035,8.035,0,0,1,7.4,5.12,8.035,8.035,0,0,1,7.4-5.12c3.63,0,7.537,2.629,7.537,8.4,0,4.282-2.475,8.741-7.355,13.254A45.393,45.393,0,0,1,15.245,30.4.62.62,0,0,1,14.933,30.487ZM7.537,4.531c-3.031,0-6.293,2.239-6.293,7.156,0,8.626,11.619,16.175,13.689,17.451C17,27.862,28.622,20.307,28.622,11.687c0-4.916-3.262-7.156-6.293-7.156-2.783,0-5.961,1.905-6.785,6.162a.622.622,0,0,1-1.222,0C13.5,6.437,10.32,4.531,7.537,4.531Z" transform="translate(0 -3.287)" fill="#fff" stroke="#fff" stroke-width="1"/>
//   </g>
// </g>
// </svg>
   
//   </button>:
// <button style ={{cursor:'pointer',color:'red'}} className="player-f-btn ">
// <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 50.669 47.417"    onClick={(e)=>handleunlike(like)}>
//   <g id="_211673_heart_icon" data-name="211673_heart_icon" transform="translate(-47.975 -63.925)">
//     <path id="Path_37764" data-name="Path 37764" d="M94.488,67.835a12.143,12.143,0,0,0-17.829,0l-3.349,3.518L69.96,67.823a12.143,12.143,0,0,0-17.829,0,15.546,15.546,0,0,0,0,21.16l21.178,22.358L94.488,89A15.546,15.546,0,0,0,94.488,67.835Z" fill="#00d35e"/>
//   </g>
// </svg>

// </button>
//               ,
// //               <button style ={{cursor:'pointer'}} className="player-f-btn md:block hidden ">
// //             <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 28.205 28.201">
// //   <path id="_1904668_connection_media_document_network_share_icon" data-name="1904668_connection_media_document_network_share_icon" d="M22.3,17.432a4.888,4.888,0,0,0-4.779,3.862L8.86,16.431a4.888,4.888,0,0,0,0-5.646L17.524,5.92a4.888,4.888,0,1,0-.094-1.409L7.967,9.824a4.888,4.888,0,1,0,0,7.57l9.463,5.313A4.888,4.888,0,1,0,22.3,17.432Zm0-16.151A3.613,3.613,0,1,1,18.69,4.894,3.613,3.613,0,0,1,22.3,1.281ZM2.327,16.157a3.613,3.613,0,0,1,5.1-5.1h0a3.613,3.613,0,0,1-5.1,5.1ZM22.3,25.932a3.613,3.613,0,1,1,3.613-3.613A3.613,3.613,0,0,1,22.3,25.932Z" transform="translate(0.514 0.492)" fill="#fff" stroke="#fff" stroke-width="1"/>
// // </svg>
// //               </button>,
//             <Link to="/my-playlist">  <button style ={{cursor:'pointer'}}className="player-f-btn md:block hidden ">
//             <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 34.049 28.199">
//   <g id="_9042900_playlist_icon" data-name="9042900_playlist_icon" transform="translate(-1.25 -4.75)">
//     <path id="Path_37752" data-name="Path 37752" d="M2,11H21.2" transform="translate(0 2.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
//     <path id="Path_37753" data-name="Path 37753" d="M2,17H14.856" transform="translate(0 4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
//     <path id="Path_37769" data-name="Path 37769" d="M2,17H12.138" transform="translate(0 12.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
//     <path id="Path_37754" data-name="Path 37754" d="M2,5H25.279" transform="translate(0 0.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
//     <path id="Path_37755" data-name="Path 37755" d="M25.16,21.08A4.08,4.08,0,1,1,21.08,17,4.08,4.08,0,0,1,25.16,21.08Z" transform="translate(4.199 7.04)" fill="#fff"/>
//     <path id="Path_37756" data-name="Path 37756" d="M25.01,32.695a4,4,0,1,1-4-4A4,4,0,0,1,25.01,32.695Zm0,0V11.6a1.6,1.6,0,0,1,1.6-1.6h3.738" transform="translate(4.199 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
//   </g>
// </svg>
//               </button></Link>,
//               <button style ={{cursor:'pointer'}}  onClick={openModal} className="player-f-btn md:block hidden ">
//             <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 28.199 28.2">
//   <g id="Layer_2" data-name="Layer 2" transform="translate(-3 -3)">
//     <path id="Path_37764" data-name="Path 37764" d="M30.156,30.7H4.044a.544.544,0,0,1-.544-.544V4.044A.544.544,0,0,1,4.044,3.5H30.156a.544.544,0,0,1,.544.544V30.156A.544.544,0,0,1,30.156,30.7ZM4.588,29.612H29.612V4.588H4.588Z" transform="translate(0 0)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="bevel" stroke-width="1"/>
//     <path id="Path_37765" data-name="Path 37765" d="M23.1,16.588H10.044a.544.544,0,0,1,0-1.088H23.1a.544.544,0,1,1,0,1.088Z" transform="translate(0.528 1.056)" fill="#fff" stroke="#fff" stroke-width="1"/>
//     <path id="Path_37766" data-name="Path 37766" d="M16.044,23.644A.544.544,0,0,1,15.5,23.1V10.044a.544.544,0,0,1,1.088,0V23.1A.544.544,0,0,1,16.044,23.644Z" transform="translate(1.056 0.528)" fill="#fff" stroke="#fff" stroke-width="1"/>
//   </g>
// </svg>
//               </button>,
              
              
              
              
//             ]}
       
//             ref={audioPlayer}
//         customProgressBarSection={
//             [
//               RHAP_UI.PROGRESS_BAR,
//               RHAP_UI.CURRENT_TIME,
//               <div>/</div>,
//               RHAP_UI.DURATION
//             ]
//           }
        
          
    
// />
// </div>}

// </div>


    
     
        
//     );
// }
