import React ,{useState,useEffect}from 'react'
import axios from 'axios';
import Load from '../components/loader/loader';


  
const TermsandConditions = () => {
  const [policy,setPolicy]=useState([])
  const[loading,setLoading]=useState(false)
  
  useEffect(() => {

    document.title = `Terms And Conditions | AfroMelodiez`
    Music();
     
   }, [])
   
    const Music = async(props) => {
      
         
      
        setLoading(true);
       
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/terms_conditions`);
       console.log('rtrttrtfvggggggggr',res)
       setLoading(false);
     
       setPolicy(res.data.data)
      
      }
    return (
      <div>
      {loading && <Load />}
  
      
    {!loading && <div className='privacy-head'><h2>{policy.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: policy.description} } />
      
      </div>
    
  }
  </div>)
}

export default TermsandConditions