import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import PlayerContext from '../playerContext/playerContext'
import '../App.css'

const Sidebar = () => {
  const loginCtx=useContext(PlayerContext)
 const isLoggedin=loginCtx.isloggedIn
  
 
    return (
      
<div className='sidebar fixed left-0 top-24 bottom-20'>

    <aside className="w-64" aria-label="Sidebar">
    <div className="overflow-y-auto  py-4 px-3s">
    <ul className="space-y-2">
    {!isLoggedin && <li className="mb-5">
     <svg className="ml-12 mb-3" xmlns="http://www.w3.org/2000/svg" width="44.326" height="44.326" viewBox="0 0 44.326 44.326">
  <g id="_7853767_kashifarif_user_profile_person_account_icon" data-name="7853767_kashifarif_user_profile_person_account_icon" transform="translate(0.05 0.05)">
    <circle id="Ellipse_333" data-name="Ellipse 333" cx="21.413" cy="21.413" r="21.413" transform="translate(0.7 0.7)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
    <path id="Path_37751" data-name="Path 37751" d="M19.159,14.9A16.878,16.878,0,0,0,4,24.375a20.87,20.87,0,0,0,15.159,6.253,21.651,21.651,0,0,0,15.159-6.253A17.222,17.222,0,0,0,19.159,14.9Z" transform="translate(2.953 12.708)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
    <circle id="Ellipse_334" data-name="Ellipse 334" cx="6.822" cy="6.822" r="6.822" transform="translate(15.291 9.038)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
  </g>
</svg>
    <div className="account-head">
    <Link to="/signin" className="flex items-center  text-base font-normal text-gray-900 rounded-lg dark:text-white ">
    
    <span className="flex-1 ml-2 mr-3 whitespace-nowrap">Sign In</span>
    </Link> <span>/</span>
    <Link to="/signup" className="flex items-center text-base font-normal text-gray-900 rounded-lg dark:text-white ">
    
    <span className="flex-1 ml-3 whitespace-nowrap">Sign Up</span>
    </Link>
    </div>
   
  
    </li>}
    
    <li>
  <Link to="/" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white ">
    
    <svg className="" xmlns="http://www.w3.org/2000/svg" width="18.339" height="15.275" viewBox="0 0 18.339 15.275">
  <path id="home" d="M18.041,8.353,10.088,2.312a1.735,1.735,0,0,0-1.912,0L.3,8.353a.709.709,0,0,0,.459,1.3h.765v4.665A3.053,3.053,0,0,0,4.582,17.3H6.112a.722.722,0,0,0,.765-.765V12.788a1.6,1.6,0,0,1,1.529-1.606H9.935a1.6,1.6,0,0,1,1.529,1.606v3.747a.766.766,0,0,0,.765.765h1.529a3.053,3.053,0,0,0,3.059-2.982V9.653h.765A.73.73,0,0,0,18.041,8.353Z" transform="translate(0.026 -2.025)" fill="#bcbcbc"/>
</svg>
    <span className="ml-3 side-link">Home</span>
    </Link>
          </li>
    <li>
    <Link to="/artists" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white ">
    <svg xmlns="http://www.w3.org/2000/svg" width="20.299" height="20.299" viewBox="0 0 20.299 20.299">
  <path id="_9111293_artist_mic_icon" data-name="9111293_artist_mic_icon" d="M12.545,5.983a4,4,0,0,0,5.488,5.462,12.321,12.321,0,0,1-3.24-2.238A12.36,12.36,0,0,1,12.545,5.983Zm1.54-1.5a10.58,10.58,0,0,0,2.122,3.306A10.445,10.445,0,0,0,19.522,9.9a4,4,0,0,0-5.436-5.411Zm-.215,9.124a6.019,6.019,0,0,1-3.482-3.482L4.3,18.884l.817.817Zm3.042.32L4.884,22.3,1.7,19.116,10.069,7.087a6,6,0,1,1,6.844,6.844Z" transform="translate(-1.701 -2)" fill="#bcbcbc" fill-rule="evenodd"/>
</svg>
    <span className="flex-1 ml-3 whitespace-nowrap">Artists</span>
    {/* <span className="inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span> */}
    </Link>
    </li>
    {/* <li>
    <Link to="listPlaylist" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white ">
    <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path><path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path></svg>
    <span className="flex-1 ml-3 whitespace-nowrap">Playlists</span>
    <span className="inline-flex justify-center items-center p-3 ml-3 w-3 h-3 text-sm font-medium text-blue-600 bg-blue-200 rounded-full dark:bg-blue-900 dark:text-blue-200">3</span>
    </Link>
    </li> */}
    {/* <li> */}
    {/* <Link to="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white ">
    <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
    <span className="flex-1 ml-3 whitespace-nowrap">Users</span>
    </Link>
    </li>
    <li>
    <Link to="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white ">
    <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clip-rule="evenodd"></path></svg>
    <span className="flex-1 ml-3 whitespace-nowrap">Products</span>
    </Link>
    </li> */}
    <li>
    <Link to="/categories" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white ">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
  <path id="_9055248_bxs_category_alt_icon" data-name="9055248_bxs_category_alt_icon" d="M10,3H4A1,1,0,0,0,3,4v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,10,3ZM20,13H14a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V14A1,1,0,0,0,20,13ZM17,3a4,4,0,1,0,4,4A4,4,0,0,0,17,3ZM7,13a4,4,0,1,0,4,4A4,4,0,0,0,7,13Z" transform="translate(-3 -3)" fill="#bcbcbc"/>
</svg>
    <span className="flex-1 ml-3 whitespace-nowrap">Categories</span>
    </Link>
    </li>
    <li>
    <Link to="/mix-songs" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75  dark:text-white group">
    <svg xmlns="http://www.w3.org/2000/svg" width="21.035" height="20.776" viewBox="0 0 21.035 20.776">
  <g id="_753949_dvds_disk_dvd_multimedia_storage_icon" data-name="753949_dvds_disk_dvd_multimedia_storage_icon" transform="translate(-0.133 -0.526)">
    <path id="Path_37757" data-name="Path 37757" d="M62.881,49.61a.951.951,0,0,1,.1.059h-.006Z" transform="translate(-42.038 -32.884)" fill="#bcbcbc"/>
    <path id="Path_37758" data-name="Path 37758" d="M63.176,49.794l-.012-.006h.006S63.173,49.791,63.176,49.794Z" transform="translate(-42.228 -33.003)" fill="#bcbcbc"/>
    <g id="Group_36130" data-name="Group 36130" transform="translate(0.133 0.526)">
      <path id="Path_37759" data-name="Path 37759" d="M18.63,13.638a9.479,9.479,0,0,0,.688-3.519,9.634,9.634,0,1,0-6.911,9.2.434.434,0,0,0,.3-.306v-.3c0-.522-.718-.36-.718-.36h0a8.408,8.408,0,0,1-2.265.313,8.543,8.543,0,1,1,8.543-8.543,8.44,8.44,0,0,1-.443,2.684l.006,0s-.237.689.042.865l.218.138A.44.44,0,0,0,18.63,13.638Z" transform="translate(-0.133 -0.526)" fill="#bcbcbc"/>
    </g>
    <path id="Path_37760" data-name="Path 37760" d="M25.038,7.315a7.421,7.421,0,0,1,7.927,5.034.458.458,0,0,1-.281.583.523.523,0,0,1-.7-.259c-.151-.41-1.76-4.612-6.222-4.375-.7.076-.983.162-1.2-.194C24.463,7.747,24.49,7.434,25.038,7.315Z" transform="translate(-16.336 -4.517)" fill="#bcbcbc"/>
    <path id="Path_37761" data-name="Path 37761" d="M6.993,32.956a7.419,7.419,0,0,0,7.461,5.7.457.457,0,0,0,.451-.465c.01-.1-.049-.57-.486-.57s-4.937-.058-6.254-4.328c-.172-.686-.187-.978-.6-1.058C7.2,32.268,6.915,32.4,6.993,32.956Z" transform="translate(-4.587 -21.244)" fill="#bcbcbc"/>
    <path id="Path_37762" data-name="Path 37762" d="M48.533,41.511l-.053-.041s-.006,0-.006-.006h-.006l-.093-.059h0l0,0-6.484-3.9-.083-.05c-.176-.1-.574-.248-.574.333v7.805s.013.615.572.295h0l6.664-3.816v0a.1.1,0,0,1,.022-.013c.021-.015.052-.034.087-.062C48.807,41.8,48.67,41.624,48.533,41.511Z" transform="translate(-27.531 -24.68)" fill="#bcbcbc"/>
    <path id="Path_37763" data-name="Path 37763" d="M20.9,17.849a3.875,3.875,0,1,0,3.874,3.876A3.875,3.875,0,0,0,20.9,17.849Zm0,5.406a1.531,1.531,0,1,1,1.531-1.531A1.531,1.531,0,0,1,20.9,23.255Z" transform="translate(-11.317 -11.606)" fill="#bcbcbc"/>
  </g>
</svg>
    <span className="ml-3">Mix Songs</span>
    </Link>
    </li>
    <li>
    {isLoggedin && <Link to="/add-playlist" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75  dark:text-white group">
    <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clip-rule="evenodd"></path></svg>
    <span className="ml-3">Add Playlist</span>
    </Link>}
    </li>
    </ul>
    <ul className="pt-4 mt-4 space-y-2 border-t border-gray-200 dark:border-gray-700">
    {/* <li>
    <Link to="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75  dark:text-white group">
    <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:group-hover:text-white dark:text-gray-400" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M378.7 32H133.3L256 182.7L378.7 32zM512 192l-107.4-141.3L289.6 192H512zM107.4 50.67L0 192h222.4L107.4 50.67zM244.3 474.9C247.3 478.2 251.6 480 256 480s8.653-1.828 11.67-5.062L510.6 224H1.365L244.3 474.9z"></path></svg>
    <span className="ml-4">Upgrade to Pro</span>
    </Link>
    </li>
    <li>
    <Link to="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75  dark:text-white group">
    <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>
    <span className="ml-3">Documentation</span>
    </Link>
    </li>
    <li>
    <Link to="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75  dark:text-white group">
    <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
    <span className="ml-3">Components</span>
    </Link>
    </li>
    <li>
    <Link to="#" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75  dark:text-white group">
    <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clip-rule="evenodd"></path></svg>
    <span className="ml-3">Help</span>
    </Link>
    
    </li> */}
    

    
   
     <li>
    <Link to="/download" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75  dark:text-white group">
   <svg xmlns="http://www.w3.org/2000/svg" width="17.467" height="17.467" viewBox="0 0 17.467 17.467">
  <path id="_8542038_download_data_icon" data-name="8542038_download_data_icon" d="M7.369,0H10.1a.817.817,0,0,1,.819.819V6.55h2.992a.681.681,0,0,1,.481,1.163L9.2,12.906a.661.661,0,0,1-.931,0l-5.2-5.192A.681.681,0,0,1,3.555,6.55h3V.819A.817.817,0,0,1,7.369,0Zm10.1,12.828v3.821a.817.817,0,0,1-.819.819H.819A.817.817,0,0,1,0,16.649V12.828a.817.817,0,0,1,.819-.819h5L7.5,13.68a1.748,1.748,0,0,0,2.477,0l1.672-1.672h5A.817.817,0,0,1,17.467,12.828Zm-4.23,3a.682.682,0,1,0-.682.682A.684.684,0,0,0,13.237,15.83Zm2.183,0a.682.682,0,1,0-.682.682A.684.684,0,0,0,15.42,15.83Z" fill="#bcbcbc"/>
</svg>
    <span className="ml-3">Download</span>
    </Link>
    
    </li>
       
   <li>
   <Link to="/my-playlist" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75  dark:text-white group">

  <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="16.5" viewBox="0 0 21.5 16.5" >
  <g id="_9042900_playlist_icon" data-name="9042900_playlist_icon" transform="translate(-1.25 -4.25)">
    <path id="Path_37752" data-name="Path 37752" d="M2,11H16" fill="#bcbcbc" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    <path id="Path_37753" data-name="Path 37753" d="M2,17H13" fill="#bcbcbc" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    <path id="Path_37754" data-name="Path 37754" d="M2,5H20" fill="#bcbcbc" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    <path id="Path_37755" data-name="Path 37755" d="M20,18.5A1.5,1.5,0,1,1,18.5,17,1.5,1.5,0,0,1,20,18.5Z" fill="#bcbcbc"/>
    <path id="Path_37756" data-name="Path 37756" d="M20,18.5A1.5,1.5,0,1,1,18.5,17,1.5,1.5,0,0,1,20,18.5Zm0,0V10.6a.6.6,0,0,1,.6-.6H22" fill="#bcbcbc" stroke="" stroke-linecap="round" stroke-width="1.5"/>
  </g>
</svg>
  <span className="ml-3">MY Playlists</span>
  </Link>
  
  </li>
  <li>
    <Link to="/liked-songs" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75  dark:text-white group">
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" color="green" viewBox="0 0 30.866 28.2"><g id="Expanded" transform="translate(0.5 -2.787)"><g id="Group_36137" data-name="Group 36137" transform="translate(0 3.287)"><path id="Path_37768" data-name="Path 37768" d="M14.933,30.487a.62.62,0,0,1-.312-.084,45.437,45.437,0,0,1-7.266-5.462C2.475,20.428,0,15.969,0,11.687c0-5.771,3.907-8.4,7.537-8.4a8.035,8.035,0,0,1,7.4,5.12,8.035,8.035,0,0,1,7.4-5.12c3.63,0,7.537,2.629,7.537,8.4,0,4.282-2.475,8.741-7.355,13.254A45.393,45.393,0,0,1,15.245,30.4.62.62,0,0,1,14.933,30.487ZM7.537,4.531c-3.031,0-6.293,2.239-6.293,7.156,0,8.626,11.619,16.175,13.689,17.451C17,27.862,28.622,20.307,28.622,11.687c0-4.916-3.262-7.156-6.293-7.156-2.783,0-5.961,1.905-6.785,6.162a.622.622,0,0,1-1.222,0C13.5,6.437,10.32,4.531,7.537,4.531Z" transform="translate(0 -3.287)" fill="#fff" stroke="#fff" stroke-width="1"></path></g></g></svg>
    <span className="ml-3">Liked Songs</span>
    </Link></li>
    {isLoggedin && <li>
     <Link to="/" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75  dark:text-white group">
    <svg className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clip-rule="evenodd"></path></svg>
    <span className="ml-3" onClick={()=>loginCtx.logout()} >Logout</span>
    </Link>
    
    </li>}
   
    </ul>
    </div>
    </aside>
       </div>
    

  )
}

export default Sidebar