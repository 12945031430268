import React,{useContext, useEffect} from 'react'
import PlayerContext from '../playerContext/playerContext';
import Redirectpage from './Redirectpage';

export const Download = () => {
  const contxt=useContext(PlayerContext)
  const isloggedin=contxt.isloggedIn

  useEffect(() => {
    document.title = `Download | AfroMelodiez`
  }, []);
  return (
    <div>
    {!isloggedin && <Redirectpage /> }
    {isloggedin && <div>
           <section className='py-12' style={{backgroundColor:" rgba(26, 99, 52, 0.25)"}}>
            <div className='container m-auto flex'>

                <div className='w-full' >
                    <div className='w-3/5 m-auto hero-content text-center'>
                        <img className='m-auto mb-5 w-96' src='img/home-page-img/laptop.png'/>
                        <h1 className='lg:mb-5 md:mb-3 mb-2 lg:text-6xl md:text-4xl text-3xl'>Download AfroMelodiez</h1>
                          <h4 className='lg:mb-5 md:mb-3 mb-2 lg:text-2xl md:text-xl text-base'>Play millions of songs on your PC and Laptop</h4>
                          <a  className='py-3 mb-5 px-16 rounded-full text-xl inline-block' style={{background:"#1ED760"}} href='https://play.google.com/store/apps/details?id=com.music_stream' target="blank">Download</a>
                          <p className='text-xl' style={{color:"#9E8585"}}>OS: 32/64-bit Win7, Win8.1 and Win10</p>
                        </div>

                       
                       
                        {/* <Link className='py-3 px-12 rounded-full text-white inline-block' style={{background:"#1ED760"}} to='#'>SIGN UP FREE</Link> */}
                    </div>
                
            </div>
        </section> 
        <section className='bg-black py-16'>
          <div className='container m-auto'>
          <div className='platform-head text-center'>
                            <p className='text-5xl text-center mb-12' style={{color:"#1ED760"}}>Available on multiple platforms</p>
                            <p className='text-3xl mb-8 text-center text-white'>Explore different listening experiences from various devices.</p>
                           <div className='store flex w-3/5 justify-between m-auto mb-5 gap-5'>
                             <div>
                            <a href='https://play.google.com/store/apps/details?id=com.music_stream' target="blank"><img src='img/home-page-img/google-play.png'/></a>
                             </div>
                             <div> <img src='img/home-page-img/app-store.png'/></div>
                            
                            <div> <img src='img/home-page-img/mac.png'/></div>

                           </div>
                        </div>
          </div>
        </section>
        </div>}

    </div>
  )
}
