import React ,{useEffect}from 'react'
import { Link } from 'react-router-dom'


const Advertisement = () => {

useEffect(() => {

  document.title = `Advertisement | AfroMelodiez`

   
 }, [])
  return (
    <div className=' privacy-head about-main container px-5'>
    <h2 className="self-center text-2xl font-semibold whitespace-nowrap text-white">Advertisement</h2>
   <p> Advertise your goods and services on AfroMelodiez to reach millions of active Android and iOS users daily. Each of these users has the potential to become one of your clients.</p>
   <p>You can reach millions of prospective consumers when they listen with AfroMelodiez. Wherever they are listening, we make it simple for millions of listeners to learn about your company. So today, begin running ads on AfroMelodiez.</p>
   <p>You may reach a broad audience with AfroMelodiez Advertising at a low price. With no additional fees, you can create an account in just a few minutes and run a campaign with little investment.</p>
   <p>The following tools are available with our advertising services:</p>
   <ol style={{ listStyleType: "lower-alpha" ,paddingLeft:"20px"}}>
  <li>Use of free professional audio mixing, voice actors, and background music.</li>
  <li>Use ad types to help you achieve your company objectives to target your audience based on their demographics, interests, and behaviours.</li>
  <li>Track and report ad performances.</li>
</ol>
   <p>Join AfroMelodiez Ad now to spread your message!</p>
  <Link to='/contact-us'> <button className='py-3 px-16 mt-4 rounded-full lg:text-xl text-base text-white inline-block'  style={{background:"#1ED760"}} href=''>ContactUs</button></Link>
    </div>
  )
}

export default React.memo(Advertisement)