import React, {useState, Suspense,useContext, useEffect } from 'react';

import { Routes, Route } from 'react-router-dom';

import { Header } from './components/Layout/Header';
import PlayerContext from '../src/playerContext/playerContext'
import MusicPlayer from './playerContext/StartPlayer';
import AboutUs from './pages/AboutUs';
import AboutUSForArtists from './pages/AboutUSForArtists';
import './App.css'
import { Signin } from './pages/signin';
import {ContactUs} from './pages/ContactUs';
import { Signup } from './pages/Signup';
import { Download } from './pages/Download';
import Load from './components/loader/loader';
import SelectedCategory from './pages/selectedCategory';
import Redirectpage from './pages/Redirectpage';
import Myplaylist from './components/listing/Myplaylist';
import HamburgerMobile from './pages/HamburgerMobile';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsandConditions from './pages/TermsandConditions';
import Advertisement from './pages/Advertisement';
import { MailRounded } from '@material-ui/icons';
import Song from './pages/Song';
import LikedSongs from './pages/likedsongs';
import NewHeader from './components/Layout/NewHeader';
import NewPlayList from './pages/PlayListSongs'
import { Google } from '@mui/icons-material';
import Modalhome from './components/Layout/Modalhome';
const AddPlaylist=React.lazy(() =>import( './pages/AddPlaylist'));
const Footer=React.lazy(()=>import('./components/Layout/Footer'))
const Home=React.lazy(()=>import('./pages'))
const Listing=React.lazy(()=>import('./pages/Listing'))
const Artist=React.lazy(() =>import( './components/crousal/Artist'));
const ArtistProfile=React.lazy(() =>import( './pages/ArtistProfile'));
const Songs=React.lazy(() =>import( './pages/Song'));
const ListArtist=React.lazy(() =>import( './components/listing/ListArtist'));
const ListMixSongs=React.lazy(() =>import( './components/listing/ListMixSongs'));
const ListPopularSongs=React.lazy(() =>import( './components/listing/ListPopularSong'));
const ListCatagories=React.lazy(() =>import( './components/listing/ListCatagories'));
const ListBestArtist=React.lazy(() =>import( './components/listing/ListBestArtist'));
const ListLatestSong=React.lazy(() =>import( './components/listing/ListLatestSong'));
const ListFeatureArtist=React.lazy(() =>import( './components/listing/ListFeatureArtist'));
const ListSelectedPlaylist=React.lazy(() =>import( './components/listing/ListSelectedPlaylist'));

const Sidebar=React.lazy(() =>import( './pages/Sidebar'));

var vid = document.getElementsByClassName("rhap_button-clear rhap_main-controls-button rhap_play-pause-button");

function App() {
  
const loginctx=useContext(PlayerContext)
const user=loginctx.isloggedIn
const [sideisShown, setSideIsShown]=useState(false);




  const showSideHandler=()=>{
    setSideIsShown(!sideisShown);
  };
  var side='main'
if(sideisShown)
{
  side='main3'
}
 
  return (
    <div className="App">
    
      <Suspense fallback={<Load />}>
				{sideisShown && <HamburgerMobile onShow={showSideHandler} />}
		
     
        <Routes>
        
          <Route exact path="/" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><Home /><Footer /><Modalhome /></div> </>} />
          
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/download" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><Download /><Footer /></div> </>} />
          
         
          <Route path="/listing" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><Listing /><Footer /></div> </>} />
          {/* <Route path="/artist" element={<><Header/><div className={side}><Artist/><Footer /></div> </>} /> */}
          <Route path="/artist/:id" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><ArtistProfile/><Footer /></div> </>} />
          <Route path="/category/:id" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><SelectedCategory/><Footer /></div> </>} />
          <Route path="/song" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><Songs/><Footer /></div> </>} />
          <Route path="/add-playlist" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><AddPlaylist/> <Footer /></div> </>} />
    
          <Route path="/artists" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><ListArtist/><Footer /></div> </>} />
          {/* <Route path="/artist" element={<><Sidebar /><Header/><div className={side}><Redirectpage /><Footer /></div> </>} /> */}
          <Route path="/mix-songs" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><ListMixSongs/><Footer /></div> </>} />
          <Route path="/popular-songs" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><ListPopularSongs/><Footer /></div> </>} />
          <Route path="/categories" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><ListCatagories/><Footer /></div> </>} />
          <Route path="/best-artists" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><ListBestArtist/><Footer /></div> </>} />
          <Route path="/latest-songs" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><ListLatestSong/><Footer /></div> </>} />
          <Route path="/feature-artists" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><ListFeatureArtist/><Footer /></div> </>} />
          <Route path="/liked-songs" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><LikedSongs/><Footer /></div> </>} />
          <Route path="/my-playlist/:id" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><NewPlayList/><Footer /></div> </>} />

         
         <Route path="/my-playlist" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><Myplaylist /><Footer /></div> </>} />
         
         <Route path="/my-playlist" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><Redirectpage /><Footer /></div> </>} />
                 <Route path="/song/:id" element={<><Sidebar /><Header onShow={showSideHandler}/><div className={side}><Song /><Footer /></div> </>} />
                 <Route path="/about-us" element={<><NewHeader /><div className='main1'><div style={{position:'relative'}}><AboutUs /></div><Footer /></div> </>} />
                 <Route path="/for-artist" element={<><NewHeader /><div className='main1'><div style={{position:'relative'}}><AboutUSForArtists /></div> <Footer /></div> </>} />
                 <Route path="/privacy-policy" element={<><NewHeader /><div className='main1'><div style={{position:'relative'}}><PrivacyPolicy /></div><Footer /></div> </>} />
                 <Route path="/terms-and-conditions" element={<><NewHeader /><div className='main1'><div style={{position:'relative'}}><TermsandConditions /> </div><Footer /></div> </>} />
                 <Route path="/advertisment" element={<><NewHeader /><div className='main1'><div style={{position:'relative'}}><Advertisement/> </div><Footer /></div> </>} />
      </Routes>

  
      </Suspense>   
      
     <MusicPlayer />
    

     
    </div>
  );
}

export default App;
