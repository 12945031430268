import { SettingsPhoneTwoTone } from '@material-ui/icons'
import React,{ useContext } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from '../../pages/Sidebar'
import PlayerContext from '../../playerContext/playerContext'
import { useHistory } from 'react-router-dom';
  const NewHeader = (props) => {
  const loginctx=useContext(PlayerContext)
   const user=loginctx.isloggedIn
  
  
	var x=localStorage.getItem('token');
	
	var navigate=useNavigate();
 
	// window.scroll({
  //   top: ,
  //   left: 2,
  //   behavior: 'smooth'
  // });
	
  return (
    <div>
		<section className='header bg-white  relative z-99'>
		<nav className=" px-2 sm:px-4 py-2.5">
  <div className="px-5 flex flex-wrap justify-between items-center mx-auto">
  <div className='flex items-center'>
  <a href="" className="flex items-center">
      <img src="/img/AI-AFRO-MELODIES-GREEN 2.jpg" className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
      <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">AfroMelodiez</span>
  </a>
</div>
  {/* <Link to='/'> */}
    <div className="md:text-xl px-2 text-sm text-gray-400 hover:text-gray-500"><button onClick={() => navigate(-1)}>Home</button></div>
    <div className="md:text-xl px-2 text-sm text-gray-400 hover:text-gray-500"><button onClick={() => navigate("/artists")}>Artists</button></div>
    <div className="md:text-xl px-2 text-sm text-gray-400 hover:text-gray-500"><button onClick={() => navigate("/categories")}>Categories</button></div>
    <div className="md:text-xl px-2 text-sm text-gray-400 hover:text-gray-500"><button onClick={() => navigate("/mix-songs" )}>Mix Songs</button></div>

  {/* </Link> */}
  <div className='download-option flex gap-8'>
  <div className='d-option'>
  <img className='' src='/img/googleplay.svg'/>
  </div>
  <div className='d-option'>
  <img className='' src='/img/ios.svg'/>
  </div>
  </div>
 
  
  </div>
  
</nav>
		</section>
       



    </div>
  )
}
export default NewHeader
