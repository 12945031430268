import React from 'react'

const Load = () => {
  return (
    <div>
      <div className=''>
    {/* <div className='absolute' style={{width:"100vw", height:"100vh",background:"rgba(0, 0, 0, .5)",zIndex:"0"}}></div> */}
    <div style={{
       display:'flex', zIindex:"99",
    position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
   }}>
        <div className="flex justify-center items-center">
  <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
    <span ><img src='/img/spinner/Spin-1s-200px.gif'/></span>
  </div>
</div>
   </div>
      </div>




    </div>
  )
}

export default Load